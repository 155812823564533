<template>
  <div id="settings">
    <router-view/>
    <!-- Modal no auth, example of validate session or token -->
    <cp-isc-modal-no-auth />
    <cp-double-btn-modal 
      v-model="isShowModalBtn"
      :title="titleModalBtn"
      :paragraph="paragraphModalBtn"
      btnMsg1="No"
      btnMsg2="Yes"
      :showBtn="isShowBtn"
      @send-result-btn1="redirectToLogin"
      @send-result-btn2="setTokenAndSession"
    />
  </div>
</template>

<script>
import {
  CpDoubleBtnModal,
  DoubleBtnMixin,
  ExpTokenMixin,
  CpIscModalNoAuth
} from "@isc/styleguide";

export default {
  name: 'App',
  components: {
    CpDoubleBtnModal,
    CpIscModalNoAuth
  },
  mixins: [DoubleBtnMixin, ExpTokenMixin],
}
</script>

<style>
#app {
  font-family: Oxygen, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
