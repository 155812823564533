//* day.js
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const getRangeDays = (rdStart, rdEnd) => {
  const ms = 24 * 60 * 60 * 1000;
  return Math.round(Math.abs(Date.parse(rdStart) - Date.parse(rdEnd)) / ms) + 1;
};

const validateRange = (rdStart, rdEnd) => {
  const daysPassed = getRangeDays(rdStart, rdEnd);
  if (daysPassed >= 30) return false;
  return true;
};

const round = (value, position, character) => {
  let valueArr = value.split("");
  valueArr.splice(valueArr.length - position, 0, ".");
  let valueStr = valueArr.toString().split(",").join("");
  let valueFloat = parseFloat(valueStr).toFixed(2);
  return valueFloat.toString().concat(character);
};
const addCharacter = (value) => {
  if(value < 0){
    return value;
  }
  value = value ? value.toString() : "0";
  if (value.length > 3) {
    if (value.length > 6) return round(value, 6, "M");
    return round(value, 3, "K");
  }
  return value;
};
const getToastAlert = (bvToast, message, title, variant, id ) => {
  let body = {
    title,
    autoHideDelay: 5000,
    appendToast: true,
    variant, 
    solid: true,
    id: id,
  }
  if(variant==null){
    Object.assign(body, {id})
  }
  bvToast.toast(message, body);
}
function transformStringDate(date, countryZone, format) {
  return dayjs.utc(date).tz(countryZone).format(format);
}
function transformDateValueOf(date, countryZone) {
  return dayjs.utc(date).tz(countryZone).valueOf();
}
//* valueOf gives milliseconds unix Time
//* Unix() gives seconds
export function getCurrentDateUtcUnixTime() {
  return dayjs.utc().unix();
}
function convertUtcTimestampToUnixTime(utcTimestamp) {
  return  dayjs.utc(utcTimestamp).unix();
}
function convertToPercentage(currentValue, maxValue) {
  if (maxValue === 0 || currentValue === 0) {
    return 0;
  }
  const percentage = (currentValue / maxValue) * 100;
  return percentage.toFixed(2);
}
function getMaxValueObject(dataObject) {
  // Step 1: Extract object values and convert into array 
  const valuesArray = Object.values(dataObject);
  // Step 2: Find maximum value using Math.max()
  return Math.max(...valuesArray);
}
// User info
export const getObjUserSession = () => {
  try {
    const dataObjUsuario = JSON.parse(sessionStorage.getItem("userObj"));
    return dataObjUsuario;
  } catch (error) {
    console.log("el error: ", error);
    return { code: 1, error: new Error(error).message };
  }
};
export function formatLikes(n, reaction) {
  if(!n) return ''
  if (n < 1e3) return n + " " + reaction;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K " + reaction;
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M " + reaction;
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B "+ reaction;
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + " T " + reaction;
}

export function convertToSuspensive(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  } else {
    return text.substring(0, maxLength - 3) + '...';
  }
}
export function capitalizeFirstLetter(str) {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return "";
}
export function fixNameSocialNetwork(name) {
  if(!name) return "";
  if(name !== "linkedin" || name !== "youtube") {
    name = capitalizeFirstLetter(name);
  };
  
  if(name === "Linkedin") name = "LinkedIn";
  if(name === "Youtube") name = "YouTube";
  
  return name;
}
export function numFormatter(num) {
  let strNum = "";
  if (isNaN(num)) return strNum;
  if (num > 999 && num < 1000000) {
    strNum = (num / 1000).toFixed(2).toString() + "K"; // convert to K for number from > 1000 < 1 million
  } else if (num > 1000000) {
    strNum = (num / 1000000).toFixed(2).toString() + "M"; // convert to M for number from > 1 million
  } else if (num < 900) {
    strNum = num.toString(); // if value < 1000, nothing to do
  }
  return strNum;
}
/**
 ** Omit keys from an object
 ** !IMPORTANT: NO USE IT FOR A NESTED OBJECT.
 * @param {*} obj
 * @param {*} keysToOmit
 * @return {*} 
 */
function simpleOmitObj(obj, keysToOmit) {
  // Create a shallow copy of the object
  return Object.keys(obj).reduce((newObj, key) => {
    // Add key-value pair to the new object if the key is not in keysToOmit
    if (!keysToOmit.includes(key)) {
      newObj[key] = obj[key];
    }
    return newObj;
  }, {});
}
// this works when I am using in the same project styleguide
// added export function
export function decodeJWT(token) {
  const [header, payload, signature] = token.split('.');

  const decodeBase64 = (str) => JSON.parse(atob(str));
  
  return {
    //header: decodeBase64(header),
    payload: decodeBase64(payload),
    //signature, // Usually not decoded as it's used for verification
  };
}

export default {
  numberWithCommas,
  getRangeDays,
  validateRange,
  addCharacter,
  getToastAlert,
  transformStringDate,
  convertToPercentage,
  getMaxValueObject,
  transformDateValueOf,
  capitalizeFirstLetter,
  fixNameSocialNetwork,
  numFormatter,
  simpleOmitObj,
  convertUtcTimestampToUnixTime,
  getCurrentDateUtcUnixTime
};
