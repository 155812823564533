var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "actionComponent" } },
    [
      _vm.visibilityLoader === true ? _c("loader-custom") : _vm._e(),
      _c(
        "b-container",
        { staticClass: "container-action" },
        [
          _c(
            "b-row",
            [
              _c("b-col", [
                _c("div", { staticClass: "mb-5" }, [
                  _c(
                    "span",
                    {
                      staticClass:
                        "font-weight-bold text-capitalize text-lsm text-secondary mr-1",
                    },
                    [_vm._v(" Campaign Results ")]
                  ),
                  _c(
                    "span",
                    { staticClass: "text-secondary" },
                    [
                      _c("i", {
                        staticClass: "fa fa-info-circle text-sm text-skygray",
                        attrs: { id: "titleProv" },
                      }),
                      _c("CpTooltipTableCustom", {
                        attrs: {
                          target: "titleProv",
                          tooltipTitle: "titleProv",
                          columns: _vm.columnsTooltipTable,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "d-flex align-items-center pl-0",
                          attrs: { cols: "6" },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "color-text text-xs mr-1" },
                            [_vm._v("Channel:")]
                          ),
                          _c(
                            "multiselect",
                            {
                              staticClass: "selectSize",
                              attrs: {
                                options: _vm.redSocial,
                                multiple: true,
                                "close-on-select": false,
                                "clear-on-select": false,
                                "preserve-search": true,
                                placeholder: "Select Channel",
                                label: "name",
                                "track-by": "name",
                                "preselect-first": true,
                                deselectLabel: "",
                                selectLabel: "",
                                selectedLabel: "",
                              },
                              on: { close: _vm.dispatchAction },
                              scopedSlots: _vm._u([
                                {
                                  key: "selection",
                                  fn: function ({ values, isOpen }) {
                                    return [
                                      values.length && !isOpen
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "multiselect__single",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  values.length !==
                                                    _vm.redSocial.length
                                                    ? values.length + " options"
                                                    : "All Selected"
                                                ) + " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.arrSocialNetworkSelected,
                                callback: function ($$v) {
                                  _vm.arrSocialNetworkSelected = $$v
                                },
                                expression: "arrSocialNetworkSelected",
                              },
                            },
                            [
                              _c("template", { slot: "noOptions" }, [
                                _c("span", [_vm._v("All selected")]),
                              ]),
                              _c("template", { slot: "tag" }, [
                                _vm._v(_vm._s("")),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "d-flex align-items-center pl-0",
                          attrs: { cols: "6" },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "color-text text-xs mr-1" },
                            [_vm._v("Actions:")]
                          ),
                          _c(
                            "multiselect",
                            {
                              staticClass: "selectSize",
                              attrs: {
                                options: _vm.actions,
                                multiple: true,
                                taggable: false,
                                "close-on-select": false,
                                "clear-on-select": false,
                                "preserve-search": true,
                                placeholder: "Select action",
                                label: "name",
                                "track-by": "name",
                                "preselect-first": true,
                                selectLabel: "",
                                deselectLabel: "",
                                selectedLabel: "",
                              },
                              on: { close: _vm.selectActionMultiple },
                              scopedSlots: _vm._u([
                                {
                                  key: "selection",
                                  fn: function ({ values, isOpen }) {
                                    return [
                                      values.length && !isOpen
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "multiselect__single",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  values.length !==
                                                    _vm.actions.length
                                                    ? values.length + " options"
                                                    : "All Selected"
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.arrActionsSelected,
                                callback: function ($$v) {
                                  _vm.arrActionsSelected = $$v
                                },
                                expression: "arrActionsSelected",
                              },
                            },
                            [
                              _c("template", { slot: "tag" }, [
                                _vm._v(_vm._s("")),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("b-col", [
                _c("p", { staticClass: "impress title-style" }, [
                  _vm._v("Impressions"),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-4" },
            [
              _c(
                "b-col",
                { staticClass: "pl-0 pr-0", attrs: { cols: "6" } },
                [
                  _c("b-row", [
                    _vm.tableactionsvisibility
                      ? _c("div", { staticClass: "box-table-action w-100" }, [
                          _c("div", { staticClass: "top-th" }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "block-1 col-4" }),
                              _c(
                                "div",
                                { staticClass: "block-2 col-4 text-center" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-weight-bold text-secondary text-xs",
                                    },
                                    [_vm._v("Organic")]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "block-3 col-4 text-center" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-weight-bold text-secondary text-xs",
                                    },
                                    [_vm._v("Paid")]
                                  ),
                                  _c("i", {
                                    staticClass: "fa fa-info-circle iconPaid",
                                    attrs: { id: "allViewPaid" },
                                  }),
                                  _c("CpTooltipWhiteCustom", {
                                    attrs: {
                                      target: "allViewPaid",
                                      title: "allViewPaid",
                                      paddingContainer: "10px 1px 10px 1px",
                                      message:
                                        "Paid campaigns only bring info from Facebook, Twitter and LinkedIn. The posts must appear on the accounts feed.",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "second-th table-head-bg" },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-4 p-0 border-tb-right" },
                                  [
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-8" }, [
                                        _c("span"),
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "col-4 p-0 text-center",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "font-weight-bold text-secondary text-xs",
                                            },
                                            [_vm._v("Sentim.")]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-4 p-0 border-tb-right" },
                                  [
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "col-4 p-0 text-center",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName: "v-b-tooltip",
                                                },
                                              ],
                                              staticClass:
                                                "font-weight-bold text-secondary text-xs",
                                              attrs: {
                                                title:
                                                  "Impressions: This metric can only be obtained from Facebook. For YouTube and Instagram, views are considered as impressions.",
                                              },
                                            },
                                            [_vm._v("Impres.")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "col-4 p-0 text-center",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName: "v-b-tooltip",
                                                },
                                              ],
                                              staticClass:
                                                "font-weight-bold text-secondary text-xs",
                                              staticStyle: {
                                                "text-align": "center",
                                              },
                                              attrs: {
                                                title:
                                                  "Reach: This metric can only be obtained from Facebook and Instagram",
                                              },
                                            },
                                            [_vm._v("Reach")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "col-4 p-0 text-center",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "font-weight-bold text-secondary text-xs",
                                            },
                                            [_vm._v("Interac")]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("div", { staticClass: "col-4 p-0" }, [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-4 p-0 text-center" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-weight-bold text-secondary text-xs",
                                          },
                                          [_vm._v("Impres.")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "col-4 p-0 text-center" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-weight-bold text-secondary text-xs",
                                          },
                                          [_vm._v("Reach")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "col-4 p-0 text-center" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-weight-bold text-secondary text-xs",
                                          },
                                          [_vm._v("Interac")]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "table-bd" },
                            _vm._l(
                              _vm.historyActions,
                              function (hisAction, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "table-bd-row" },
                                  [
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-4 p-0" }, [
                                        _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-8 pl-0 pr-0 pt-2 pb-2",
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.levantarModal(
                                                        hisAction
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: hisAction.post_imagen,
                                                      width: "30px",
                                                      height: "30px",
                                                      onerror:
                                                        "this.onerror=null;this.src='https://cdn4.iconfinder.com/data/icons/solid-part-6/128/image_icon-128.png';",
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "ml-2 font-weight-bold text-secondary text-xs",
                                                },
                                                [_vm._v(_vm._s(hisAction.name))]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-4 p-0 text-center d-flex justify-content-center align-items-center",
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "text-xs" },
                                                [
                                                  _c("i", {
                                                    class: [
                                                      "fas",
                                                      hisAction.sentimentId == 1
                                                        ? "tb_neutral"
                                                        : hisAction.sentimentId ==
                                                          2
                                                        ? "tb_pos"
                                                        : hisAction.sentimentId ==
                                                          3
                                                        ? "tb_neg"
                                                        : "fa-ban",
                                                    ],
                                                    staticStyle: {
                                                      "font-size": "16px",
                                                      color: "#828a96",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-4 p-0 d-flex align-items-center",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "row w-100" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-4 p-0 text-center",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-color-text text-xs",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          hisAction.organicImpressions
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-4 p-0 text-center",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-color-text text-xs",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          hisAction.organicReach
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-4 p-0 text-center",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-color-text text-xs",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          hisAction.organicInteractions
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-4 p-0 d-flex align-items-center",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "row w-100" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-4 p-0 text-center",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-color-text text-xs",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          hisAction.paidImpressions
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-4 p-0 text-center",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-color-text text-xs",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          hisAction.paidReach
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-4 p-0 text-center",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-color-text text-xs",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          hisAction.paidInteractions
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  !_vm.tableactionsvisibility
                    ? _c("div", { staticClass: "image-container-off" }, [
                        _c("img", {
                          staticClass: "images-off opacity-50",
                          attrs: {
                            src: require("../../../../assets/all-images/img-off-01.png"),
                            alt: "",
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "image-container-off__message" },
                          [
                            _c("div", [_vm._v(_vm._s(_vm.titleImgOff))]),
                            _vm._v(" " + _vm._s(_vm.msgImgOff) + " "),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  !_vm.paidorganivisibility
                    ? _c("div", { staticClass: "image-container-off" }, [
                        _c("img", {
                          staticClass: "images-off opacity-50",
                          attrs: {
                            src: require("../../../../assets/all-images/impresions-of.png"),
                            alt: "",
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "image-container-off__message" },
                          [
                            _c("div", [_vm._v(_vm._s(_vm.titleImgOff))]),
                            _vm._v(" " + _vm._s(_vm.msgImgOffGraphic) + " "),
                          ]
                        ),
                      ])
                    : _c("impresiones", {
                        ref: "impresionesGraf",
                        attrs: { grafico: this.grafInter },
                      }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "row-grafs" },
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("div", { staticClass: "mb-3" }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "font-weight-bold text-capitalize text-lsm text-secondary mr-1",
                      },
                      [_vm._v(" Impact ")]
                    ),
                    _c(
                      "span",
                      { staticClass: "text-secondary" },
                      [
                        _c("i", {
                          staticClass: "fa fa-info-circle text-sm text-skygray",
                          attrs: { id: "impact1" },
                        }),
                        _c("CpTooltipWhiteCustom", {
                          attrs: {
                            target: "impact1",
                            title: "Tooltip title",
                            message:
                              "Number of interactions by reach and impressions",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm.impactvisibility
                    ? _c("highcharts", {
                        staticStyle: { display: "inherit !important" },
                        attrs: { options: _vm.optionFirst },
                      })
                    : _c("div", { staticClass: "image-container-off" }, [
                        _c("img", {
                          staticClass: "images-off",
                          attrs: {
                            src: require("../../../../assets/all-images/img-off-03.png"),
                            alt: "",
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "image-container-off__message" },
                          [
                            _c("div", [_vm._v(_vm._s(_vm.titleImgOff))]),
                            _vm._v(" " + _vm._s(_vm.msgImgOffGraphic) + " "),
                          ]
                        ),
                      ]),
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "inpactTabs", attrs: { cols: "6" } },
                [
                  _vm.interreactiosvisibility
                    ? _c(
                        "b-tabs",
                        { attrs: { align: "center" } },
                        [
                          _c(
                            "b-tab",
                            {
                              attrs: { active: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "title",
                                    fn: function () {
                                      return [
                                        _c("span", [
                                          _vm._v("Interaction Analysis"),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                684954446
                              ),
                            },
                            [
                              _c("interactionsAnalytics", {
                                ref: "interactionsanalisis",
                                attrs: { grafico: this.grafInter },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-tab",
                            {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "title",
                                    fn: function () {
                                      return [
                                        _c("span", [_vm._v("Reactions ")]),
                                        _c("i", {
                                          staticClass: "fab fa-facebook",
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                3156204421
                              ),
                            },
                            [
                              _c("reactionsFacebook", {
                                ref: "reactionsFacebook",
                                attrs: { grafico: this.grafInter },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-tab",
                            {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "title",
                                    fn: function () {
                                      return [
                                        _c("span", [_vm._v(" Reactions ")]),
                                        _c("i", {
                                          staticClass: "fab fa-linkedin",
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                3177707947
                              ),
                            },
                            [
                              _c("reactionLinkedin", {
                                ref: "reactionLinkedin",
                                attrs: { grafico: this.grafInter },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c("div", { staticClass: "image-container-off" }, [
                        _c("img", {
                          staticClass: "images-off opacity-50",
                          attrs: {
                            src: require("../../../../assets/all-images/analitycs-off.png"),
                            alt: "",
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "image-container-off__message" },
                          [
                            _c("div", [_vm._v(_vm._s(_vm.titleImgOff))]),
                            _vm._v(" " + _vm._s(_vm.msgImgOff) + " "),
                          ]
                        ),
                      ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "row-grafs" },
            [
              _c(
                "b-col",
                [
                  _c("div", { staticClass: "mb-3" }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "font-weight-bold text-capitalize text-lsm text-secondary mr-1",
                      },
                      [_vm._v(" Overall Sentiment ")]
                    ),
                    _c(
                      "span",
                      [
                        _c("i", {
                          staticClass: "fa fa-info-circle text-sm text-skygray",
                          attrs: { id: "overrall-sentiment" },
                        }),
                        _c("CpTooltipWhiteCustom", {
                          attrs: {
                            target: "overrall-sentiment",
                            title: "Tooltip title",
                            message:
                              "Sentiment generated daily in the publications associated with the campaign.",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  !_vm.overaalsentimentvisibility
                    ? _c("div", { staticClass: "image-container-off" }, [
                        _c("img", {
                          staticClass: "images-off",
                          attrs: {
                            src: require("../../../../assets/all-images/chart_action_sent_bg.png"),
                            alt: "",
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "image-container-off__message" },
                          [
                            _c("div", [_vm._v(_vm._s(_vm.titleImgOff))]),
                            _vm._v(" " + _vm._s(_vm.msgImgOffGraphic) + " "),
                          ]
                        ),
                      ])
                    : _c("highcharts", {
                        staticClass: "grafico_all",
                        staticStyle: { display: "inherit !important" },
                        attrs: { options: _vm.optionsSecond },
                      }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.overaalsentimentvisibility == false
        ? _c("div", { staticClass: "clearImage" })
        : _vm._e(),
      _c("contentPost", { ref: "modalcont", attrs: { obj: _vm.objpost } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }