const swal = require("sweetalert2");
function validationCodeToken(data) {
  try {
    if (data.code === 1002) {
      sweetAlertModal(
        "Session expired",  
        "The Session has expired, we will redirect to the login.",
        "error",
        "Ok"
      );
    } else if (data.code === 1001) {
      sweetAlertModal(
        "Token invalid",
        "The Token is invalid, we will redirect to the login",
        "error",
        "Ok"
      );
    } else {
      return data.data;
    }
  } catch (error) {
    console.error("[Error] in function: validationCodeToken ", error);
  }
}
//* Helper functions

function sweetAlertModal(title, text, icon, confirmButtonText) {
  swal
    .fire({
      title: title,
      text: text,
      icon: icon,
      confirmButtonText: confirmButtonText,
    })
    .then(() => {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/login";
    });
};

module.exports = {
  validationCodeToken,
};
