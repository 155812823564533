import axios from "axios";

const { validationCodeToken } = require("../util/validateCodeToken");
import { getCredentialsHeader } from "../util/util";
//* PROD URL CAMPAIGN
const base_url = process.env.VUE_APP_BASE_URL;
//* LOCAL URL
//const base_url = "http://localhost:3000/api/v1";

export const listDetailCampaign = async (idCampaign) => {
  try {
    let configAxios = {};
    configAxios.method = "get";
    configAxios.url = `${base_url}/campaign/detail/${idCampaign}`;
    configAxios.headers = getCredentialsHeader();
    const { data } = await axios(configAxios);
    let result = validationCodeToken(data);
    return result.data;
  } catch (error) {
    return -1;
  }
};
export const listMetrics = async (description) => {
  try {
    let configAxios = {};
    configAxios.method = "post";
    configAxios.url = `${base_url}/campaign/listMetricsDescription`;
    configAxios.headers = getCredentialsHeader();
    configAxios.data = {
      description: description,
    };
    const { data } = await axios(configAxios);
    let result = validationCodeToken(data);
    return result.data;
  } catch (error) {
    return -1;
  }
};
export const updateCampaigns = async (idCampaign, campaign) => {
  delete campaign.table;
  try {
    let configAxios = {};
    configAxios.method = "put";
    configAxios.url = `${base_url}/campaign/${idCampaign}`;
    configAxios.headers = getCredentialsHeader();
    configAxios.data = {
      data: campaign,
    };
    const { data } = await axios(configAxios);

    let result = validationCodeToken(data);
    return result.data;
  } catch (error) {
    return -1;
  }
};
export const listCurrency = async () => {
  try {
    let configAxios = {};
    configAxios.method = "get";
    configAxios.url = `${base_url}/campaign/listCurrency`;
    configAxios.headers = getCredentialsHeader();
    const { data } = await axios(configAxios);
    let result = validationCodeToken(data);
    return result.data;
  } catch (error) {
    return -1;
  }
};
export const listRedSocial = async (campaign_id) => {
  try {
    let configAxios = {};
    configAxios.method = "get";
    configAxios.url = `${base_url}/campaign/redessocialesconv/${campaign_id}`;
    configAxios.headers = getCredentialsHeader();
    const { data } = await axios(configAxios);
    let result = validationCodeToken(data);
    return result.data;
  } catch (error) {
    console.log(">>>", error);
    return -1;
  }
};
export const listPostHitoricoAction = async (dataSend) => {
  try {
    let configAxios = {};
    configAxios.method = "post";
    configAxios.url = `${base_url}/campaign/buscarHistoricoCampRedSocial`;
    configAxios.headers = getCredentialsHeader();
    configAxios.data = dataSend;
    const { data } = await axios(configAxios);
    let result = validationCodeToken(data);
    return result.data;
  } catch (error) {
    console.log(">>>", error);
    return -1;
  }
};
export const listaMarca = async (id_cliente) => {
  try {
    let configAxios = {};
    configAxios.method = "get";
    configAxios.url = `${base_url}/campaign/marcas/${id_cliente}`;
    configAxios.headers = getCredentialsHeader();
    const { data } = await axios(configAxios);
    let result = validationCodeToken(data);
    return result.data;
  } catch (error) {
    console.log(">>>", error);
    return -1;
  }
};
export const listaMonedas = async () => {
  try {
    let configAxios = {};
    configAxios.method = "post";
    configAxios.url = `${base_url}/campaign/postMonedas`;
    configAxios.headers = getCredentialsHeader();
    const { data } = await axios(configAxios);
    let result = validationCodeToken(data);
    return result.data;
  } catch (error) {
    console.log(">>>", error);
    return -1;
  }
};
export const consulta = async (id_marca) => {
  try {
    let configAxios = {};
    configAxios.method = "get";
    configAxios.url = `${base_url}/campaign/getResumenTotal/${id_marca}`;
    configAxios.headers = getCredentialsHeader();
    const { data } = await axios(configAxios);

    let result = validationCodeToken(data);
    return result.data;
  } catch (error) {
    console.log(">>>", error);
    return -1;
  }
};
export const resumenTotal = async (id_marca, id_moneda) => {
  try {
    let configAxios = {};
    configAxios.method = "get";
    configAxios.url = `${base_url}/campaign/listatotal/${id_marca}/${id_moneda}`;
    configAxios.headers = getCredentialsHeader();
    const { data } = await axios(configAxios);

    let result = validationCodeToken(data);
    return result.data;
  } catch (error) {
    console.log(">>>", error);
    return -1;
  }
};
export const detallAll = async (id_marca, id_cliente, page) => {
  try {
    let configAxios = {};
    configAxios.method = "get";
    configAxios.url = `${base_url}/campaign/buscarPorMarca/${id_marca}/${id_cliente}/?page=${page}`;
    configAxios.headers = getCredentialsHeader();
    const { data } = await axios(configAxios);
    let result = validationCodeToken(data);
    return result.data;
  } catch (error) {
    console.log(">>>", error);
    return -1;
  }
};
//* Check it!!
// export const redSocMarCamp = async (id_marca,id_campania) => {
//   try {
//     let configAxios = {}
//     configAxios.method = 'get'
//     configAxios.url = `${base_url}/campaign/buscarRedSocPorMarca/${id_marca}/${id_campania}`
//     configAxios.headers = getCredentialsHeader()
//     const { data } = await axios(configAxios);

//     return validationCodeToken(data);
//   } catch (error) {
//     console.log(">>>",error);
//     return -1;
//   }
// };
export const buscarHistoricoCamps = async (id_campania, fec_ini, fec_fin) => {
  try {
    let configAxios = {};
    configAxios.method = "get";
    configAxios.url = `${base_url}/campaign/sumPostHistCamps/${id_campania}?fec_ini=${fec_ini}&fec_fin=${fec_fin}`;
    configAxios.headers = getCredentialsHeader();
    const { data } = await axios(configAxios);
    let result = validationCodeToken(data);
    return result.data;
  } catch (error) {
    console.log(">>>", error);
    return -1;
  }
};
export const listarRedSocCampOnes = async (id_campania) => {
  try {
    let configAxios = {};
    configAxios.method = "get";
    configAxios.url = `${base_url}/campaign/listarRedSocCampaniaOne/${id_campania}`;
    configAxios.headers = getCredentialsHeader();
    const { data } = await axios(configAxios);
    let result = validationCodeToken(data);
    return result.data;
  } catch (error) {
    console.log(">>>", error);
    return -1;
  }
};
export const listConversiones = async (id_campania, id_red_social) => {
  try {
    let configAxios = {};
    configAxios.method = "get";
    configAxios.url = `${base_url}/campaign/conversiones/${id_campania}/${id_red_social}`;
    configAxios.headers = getCredentialsHeader();
    const { data } = await axios(configAxios);
    let result = validationCodeToken(data);
    return result.data;
  } catch (error) {
    console.log(">>>", error);
    return -1;
  }
};
// export const listarintereses = async () => {
//   try {
//     //LISTENING
//     const { data } = await axios.get(`${process.env.VUE_APP_API_LISTENING}/geo_drivers/`, {
//       headers,
//     });
//     return validationCodeToken(data);
//   } catch (error) {
//     console.log(">>>",error);
//     return -1;
//   }
// };
// export const geo_interest = async (pais) => {
//   try {
//     //LISTENING
//     const { data } = await axios.post(`${process.env.VUE_APP_API_LISTENING}/geo_interest/${pais}`, {
//       headers,
//     });
//     return validationCodeToken(data);
//   } catch (error) {
//     console.log(">>>",error);
//     return -1;
//   }
// };
export const listPostDateID = async (id_campania) => {
  try {
    let configAxios = {};
    configAxios.method = "get";
    configAxios.url = `${base_url}/campaign/listPostDateID/${id_campania}`;
    configAxios.headers = getCredentialsHeader();
    const { data } = await axios(configAxios);
    let result = validationCodeToken(data);
    return result.data;
  } catch (error) {
    console.log(">>>", error);
    return -1;
  }
};
export const getGraficoTotalizado = async (id_campania) => {
  try {
    let configAxios = {};
    configAxios.method = "get";
    configAxios.url = `${base_url}/campaign/getGraficoTotalizado/${id_campania}`;
    configAxios.headers = getCredentialsHeader();
    const { data } = await axios(configAxios);
    let result = validationCodeToken(data);
    return result.data;
  } catch (error) {
    console.log(">>>", error);
    return -1;
  }
};
export const getGrafTotxRedSoc = async (id_campania, id_red_social) => {
  try {
    let configAxios = {};
    configAxios.method = "get";
    configAxios.url = `${base_url}/campaign/getGrafTotxRedSoc/${id_campania}/${id_red_social}`;
    configAxios.headers = getCredentialsHeader();
    const { data } = await axios(configAxios);
    let result = validationCodeToken(data);
    return result.data;
  } catch (error) {
    console.log(">>>", error);
    return -1;
  }
};
