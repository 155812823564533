<template>
  <div class="w-100 bg-mainbg">
    <loader-custom
      v-if="visibilityLoader || loader"
    ></loader-custom>
    <bread-crumb />
    <div class="mt-3">
      <b-container fluid class="detail-content-box">
        <div class="w-100 d-flex justify-content-end mb-2">
          <b-button
            variant="primary"
            style="font-size: 13px"
            class="shadow px-5 py-2"
            @click="modalAddAccount = true"
          >
            Add Account
          </b-button>
        </div>
        <div v-if="accounts.length > 0">
          <div class="d-flex flex-wrap justify-content-between">
            <account-box
              v-for="(account, index) in accounts"
              :key="index"
              :accountIndex="index"
              :accountData="account"
              @reload="getAccounts"
              @error="emitError"
              @ok="emitOk"
              @okUpdate="emitOkUpdate"
            />
          </div>
          <div class="row pd-lr-10">
            <div class="col-md-12">
              <div id="paginado">
                <ul id="pagination">
                  <li
                    :class="[
                      'li_paginado',
                      index == currentpage ? 'select_paginado' : '',
                    ]"
                    @click="getAccounts(index)"
                    v-for="index in totalPages"
                    :key="index"
                    id="1"
                    style=""
                  >
                    {{ index }}
                  </li>
                </ul>
                <div class="clear"></div>
              </div>
            </div>
          </div>
        </div>

        <cp-warning-info
          v-else
          info-text="Add an account and link the social media channels to start using MONITOR."
          padding-container="40px 20px"
        />
      </b-container>
    </div>

    <!-- MODAL ADD ACCOUNT -->
    <b-modal
      no-close-on-backdrop
      v-model="modalAddAccount"
      size="lg"
      header-class="border-0 pr-5 btn-close-header"
      hide-footer
      :no-enforce-focus="true"
    >
      <template v-slot:modal-header-close>
        <CloseButton class="p-0" :fill="customFill" />
      </template>
      <add-account-modal @onClose="getResult" :idClient="idClient" />
    </b-modal>
    <!-- <ModalTokensExpired
      :active="modalactive"
      :cuentas="cuentasexpired"
    ></ModalTokensExpired> -->
  </div>
</template>

<script>
import AccountBox from "../components/AccountBox.vue";
import ModalTokensExpired from "../components/modal-contents/tokenExpired/ModalTokensExpired.vue";
import AddAccountModal from "../components/modal-contents/addAccount/Main";
import * as OverviewService from "@/services/overview.service";
import {
  loaderCustom,
  mapMutations,
  mapGetters,
  UserObjMixin,
  BreadCrumb,
  CpWarningInfo,
  CloseButton
} from "@isc/styleguide";
import { orderStringAlphabetically } from "../util/util";

export default {
  name: "Accounts",
  components: {
    BreadCrumb,
    AccountBox,
    AddAccountModal,
    ModalTokensExpired,
    loaderCustom,
    CpWarningInfo,
    CloseButton
  },
  mixins: [UserObjMixin],
  data() {
    return {
      modalAddAccount: false,
      accounts: [],
      currentpage: 1,
      totalPages: 1,
      cuentasexpired: [],
      modalactive: false,
      visibilityLoader: true,
      idClient: 0,
      customFill: "#000"
    };
  },

  async mounted() {
    await this.setCurrentObjUser();
    await this.getAccounts(1);
    //await this.gettokensExpired();
    this.visibilityLoader = false;
    this.idClient = this.userInfo.id_cliente;
    console.log("process.env.NODE_ENV dev_mode: ", process.env.NODE_ENV);
  },
  methods: {
    ...mapMutations({
      setLoader: "global/helper/setLoader",
    }),
    getResult(result) {
      if (result.code && result.code == "success") {
        this.$bvToast.toast(
          "The account was successfully created, you will be able to see your metrics within 24 hours approx.",
          {
            title: "Completed",
            autoHideDelay: 2500,
            appendToast: true,
            variant: "success",
            solid: true,
          }
        );
      } else if (result.code && result.code == "error") {
        this.$bvToast.toast("[Error] Server fails to create an account.", {
          title: "Error",
          autoHideDelay: 5000,
          appendToast: true,
          variant: "danger",
          solid: true,
        });
      }
      this.getAccounts(1);
      this.modalAddAccount = false;
    },
    async getAccounts(page) {
      this.visibilityLoader = true;
      try {
        const _accounts = await OverviewService.listAccountsByClient(page);

        if (_accounts === -1) {
          this.$bvToast.toast("There was an error to load users' accounts.", {
            title: "Error",
            autoHideDelay: 5000,
            appendToast: true,
            variant: "danger",
            solid: true,
          });
        }
        if (_accounts.marcas.length === 0) {
          this.visibilityLoader = false;
          this.setLoader(false);
        }
        this.currentpage = _accounts.pagination.currentPage;
        this.totalPages = _accounts.pagination.totalPages;
        this.accounts = _accounts.marcas.map((marca) => {
          let audienceLevel = 0;
          let totalPosts = 0;
          let totalInteractions = 0;
          let social_networks = [];
          let engaged_users = 0;
          for (const ctaRedSocial of marca.CuentasRedSocial) {
            audienceLevel += Number(ctaRedSocial.numAudienceAll);
            totalPosts += Number(ctaRedSocial.numPostsAll);
            engaged_users += Number(ctaRedSocial.numEngagedUsers);
            totalInteractions += Number(ctaRedSocial.numInteractions);
            if (ctaRedSocial.RedSocial) {
              social_networks.push(
                ctaRedSocial.RedSocial.nomRedSocial.toLowerCase()
              );
            }
          }
          this.setLoader(false);
          return {
            id: marca.idMarca,
            image: marca.CuentasRedSocial[0].dscLogo,
            title: marca.dscMarca,
            fec_creacion: marca.fec_creacion,
            fec_modificacion: marca.fec_modificacion,
            audienceLevel,
            social_networks: orderStringAlphabetically(social_networks),
            totalPosts: totalPosts,
            engaged_users: engaged_users,
            totalInteractions: totalInteractions,
          };
        });
        this.visibilityLoader = false;
      } catch (error) {}
    },
    emitError() {
      this.$bvToast.toast("There was an error to delete an account.", {
        title: "Error",
        autoHideDelay: 5000,
        appendToast: true,
        variant: "danger",
        solid: true,
      });
    },
    emitOk(_accountsDeleted) {
      this.$bvToast.toast(`Account was deleted.`, {
        title: "Completed",
        autoHideDelay: 2500,
        appendToast: true,
        variant: "success",
        solid: true,
      });
    },
    async emitOkUpdate(result) {
      await this.getAccounts(1);
      this.$bvToast.toast(result, {
        title: "Completed",
        autoHideDelay: 2500,
        appendToast: true,
        variant: "success",
        solid: true,
      });
    },
    async gettokensExpired() {
      const res = await OverviewService.expiredAccounts();
      if (res.length > 0) {
        this.cuentasexpired = res;
      }
    },
  },
  computed: {
    ...mapGetters({
      loader: "global/helper/getLoader",
    }),
  },
};
</script>
<style>
#paginado {
  margin-top: 0.8em;
}
#pagination {
  float: right;
}
#pagination {
  text-align: center;
  margin: 0px;
  padding: 0px;
  margin-bottom: 10px;
}
.select_paginado {
  background-color: rgb(0, 165, 255) !important;
  color: rgb(255, 255, 255) !important;
  border: 1px solid rgb(0, 165, 255) !important;
}
.li_paginado {
  height: 35px;
  width: 35px;
  background-color: transparent;
  border-radius: 0.5em;
  color: #5d6b88;
  line-height: 1.6em;
  border: 1px solid #c2d1e6;
  cursor: pointer;
}
.li_paginado {
  list-style: none;
  float: left;
  margin-right: 2px;
  padding: 7px;
  background: #e7eaef;
  font-size: 12px !important;
}
.li_paginado:hover {
  background-color: rgb(0, 165, 255) !important;
  color: rgb(255, 255, 255) !important;
  border: 1px solid rgb(0, 165, 255) !important;
}
.btn-close-header .close:not(:disabled):not(.disabled):hover {
 opacity: 0.5;
}
.btn-close-header .close:not(:disabled):not(.disabled):focus{
  opacity: 0.5;
}
</style>
