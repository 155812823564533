import axios from "axios";

const base_url = process.env.VUE_APP_DEV_BACK_MONITOR;
//* this url comes from an a job.
const url_record_benchmark = process.env.VUE_APP_URL_GRABAR;
const { validationCodeToken } = require("../util/validateCodeToken");
import { getCredentialsHeader } from "../util/helper";
//*Localhost
//const base_url = "http://localhost:3001/api/v1";

export const listPostByClient = async () => {
  try {
    const id_post = 1; // TODO : esto será llamado desde el localstorage junto con el token
    const { data } = await axios.get(`${base_url}/benchmark/${id_post}/post`, {
      headers: getCredentialsHeader(),
    });
    return validationCodeToken(data);
  } catch (error) {
    console.log(error);
    return -1;
  }
};

export const audiencia = async (id_cuenta_red_social, id_red_social) => {
  try {
    const { data } = await axios.get(
      `${base_url}/channels/${id_cuenta_red_social}/${id_red_social}/facebook`,
      {
        headers: getCredentialsHeader(),
      }
    );
    return validationCodeToken(data);
  } catch (error) {
    console.log(error);
    return -1;
  }
};

export async function getPerfilByIdClient(dataSend) {
  try {
    let configAxios = {};
    configAxios.method = "get";
    configAxios.url =
      base_url + "/benchmark/" + dataSend.opcRedSocial + "/perfileRedSocial";
    configAxios.params = {
      id_cliente: dataSend.id_client,
    };
    configAxios.headers = getCredentialsHeader();
    const { data } = await axios(configAxios);
    return validationCodeToken(data);
  } catch (error) {
    console.log(error);
    return -1;
  }
}

export async function getDetalleBeanchmark(
  dataSend,
  idCuentaRedSocial,
  opcRedSocial
) {
  try {
    let configAxios = {};
    configAxios.method = "get";
    configAxios.url =
      base_url +
      "/benchmark/" +
      idCuentaRedSocial +
      "/" +
      opcRedSocial +
      "/posts";
    configAxios.params = dataSend;
    configAxios.headers = getCredentialsHeader();
    const { data } = await axios(configAxios);
    return  validationCodeToken(data);
  } catch (error) {
    console.log(error);
    return -1;
  }
}

export async function refreshDetalleBeanchmark(dataSend) {
  try {
    let configAxios = {};
    configAxios.method = "get";
    configAxios.url =
      base_url +
      "/benchmark/" +
      dataSend.nomCuentaRedSocial +
      "/" +
      dataSend.opcRedSocial +
      "/posts";
    configAxios.params = dataSend.data;
    configAxios.headers = getCredentialsHeader();
    const { data } = await axios(configAxios);
    return validationCodeToken(data);
  } catch (error) {
    console.log(error);
    return -1;
  }
}

export async function grabarBeanchmark(dataSend) {
  try {
    let configAxios = {};
    configAxios.method = "get";
    configAxios.url = url_record_benchmark + "/" + dataSend.username;
    configAxios.params = dataSend.data;
    const { response, status, data } = await axios(configAxios);
    return validationCodeToken(data);
  } catch (error) {
    throw error;
  }
}

export async function searchPostsbyRedSocial(dataSend) {
  try {
    let configAxios = {};
    (configAxios.method = "get"),
      (configAxios.url = base_url + "/benchmark" + "/searchPostsbyRedSocial");
    configAxios.params = dataSend;
    configAxios.headers = getCredentialsHeader();
    const { data } = await axios(configAxios);
    return validationCodeToken(data);
  } catch (error) {
    console.log(error);
    return -1;
  }
}
