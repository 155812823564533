import axios from "axios";
//* PROD URL OVERVIEW BACK
const base_url = process.env.VUE_APP_BACK_MONITOR_OVERVIEW;
const respUserSession = require('../util/util').getObjUsuarioSession() || {}
const { validationCodeToken } = require('../util/validateCodeToken')
import { getCredentialsHeader } from "../util/util";
//* LOCAL URL 
//const base_url = 'http://localhost:3000/api/v1'

/* 
  * validation if the client has a limit of consume into table "T_PERIODO_CONSUMO_CLIENTE"
*/
export const getValidationLimitAccountBySN = async (socialNetworkId, idModulo) => {
  try {
    let jsonAxios = {
      method: 'post',
      url: `${base_url}/accounts/restriction-account`,
      headers: getCredentialsHeader(),
      data: {
        socialNetworkId,
        clientId: respUserSession.id_cliente,
        idModulo
      }
    }
    const { data } = await axios(jsonAxios);
    return validationCodeToken(data);
  } catch (error) {
    return error.response;
  }
}

export const getConsumeHistoryByCampaignId = async (campaignId) => {
  try {
    let jsonAxios = {
      method: 'post',
      url: `${base_url}/restrict/find-history-consume-campaign`,
      headers: getCredentialsHeader(),
      data: {
        campaignId,
        clientId: respUserSession.id_cliente,
      }
    }
    const { data } = await axios(jsonAxios);

    return validationCodeToken(data)
  } catch (error) {
    return error.response;
  }
};
export const updateRestrictAccount = async (dataConsume, idModule) => {
  try {
    
    let jsonAxios = {
      method: 'put',
      url: `${base_url}/restrict/update-period-consume`,
      headers: getCredentialsHeader(),
      data: {
        data: dataConsume,
        clientId: respUserSession.id_cliente,
        idModule
      }
    }
    const { data } = await axios(jsonAxios);

    return validationCodeToken(data)
  } catch (error) {
    return error.response;
  }
};

/* export const insertRestrictAccount = async (dataConsume) => {
  try {
    dataConsume.idCliente = respUserSession.id_cliente;
    let jsonAxios = {
      method: 'post',
      url: `${base_url}/restrict/save-period-consume`,
      headers: headers,
      data: dataConsume
    }
    const { data } = await axios(jsonAxios);

    return validationCodeToken(data)
  } catch (error) {
    return error.response;
  }
};
 */