const _API_KEY = process.env.VUE_APP_API_KEY;

export function getLocalStorage() {
  const _TOKEN = sessionStorage.getItem("token");
  //const _REFRESH_TOKEN = sessionStorage.getItem("refreshToken");
  const _IP_PUBLIC = sessionStorage.getItem("ip-public");
  const _IP_LOCAL = sessionStorage.getItem("ip-local");
  return {
    Authorization: `Bearer ${_TOKEN}|${_IP_PUBLIC}|${_IP_LOCAL}`,
    api_key: _API_KEY
  };
}

/* export function setToken(newToken) {
  sessionStorage.setItem("token", newToken);
} */
