<template>
  <div>
    <b-container fluid class="container-login-inner">
      <div class="title-login">{{ $t("login.title") }}</div>
      <div class="subtitle-login">{{ $t("login.subtitle") }}</div>
      <form action="" @submit.prevent="login()">
        <div>
          <input
            type="text"
            class="input-text"
            v-model="idNameGroup"
            :placeholder="$t('login.form.idNameGroup')"
            data-cy="id-name-group"
          />
        </div>
        <div class="error" v-if="!$v.idNameGroup.required && loginPressed">
          {{ $t("login.form.idNameGroupRequired") }}
        </div>
        <div>
          <input
            type="text"
            class="input-text"
            v-model="email"
            :placeholder="$t('login.form.email')"
            data-cy="email"
          />
        </div>
        <div class="error" v-if="!$v.email.required && loginPressed">
          {{ $t("login.form.emailRequired") }}
        </div>
        <div class="input-login">
          <input
            :type="typeInput"
            class="input-text"
            v-model="password"
            :placeholder="$t('login.form.password')"
            data-cy="password"
          />
          <span class="eye-password" @click="viewPassword">
            <i :class="[showPassword ? 'fa fa-eye' : 'fa fa-eye-slash']"></i>
          </span>
        </div>
        <div class="error" v-if="!$v.password.required && loginPressed">
          {{ $t("login.form.passwordRequired") }}
        </div>
        <div class="button-container">
          <button
            type="submit"
            class="button-login"
            data-cy="submit"
            v-if="!boolLoading && !boolIngresando"
          >
            {{ $t("login.form.login") }}
          </button>
          <button
            type="submit"
            class="button-login"
            v-if="!boolLoading && boolIngresando"
          >
            {{ $t("login.form.ingresando") }}
          </button>
          <button class="button-loading" v-if="boolLoading && !boolIngresando">
            <div style="display: inline-block">
              {{ $t("login.form.cargando") }}
            </div>
            <div style="display: inline-block; padding-left: 15px">
              <b-spinner variant="light" label="Large Spinner"></b-spinner>
            </div>
          </button>
        </div>
      </form>
      <div class="container-buttom-text">
        {{ $t("login.msg1") }}
        <router-link
          to="/login/register"
          style="text-decoration: underline"
          class="link-bottom"
          >{{ $t("login.link1") }}</router-link
        >
      </div>
      <div class="container-buttom-text">
        {{ $t("login.msg2") }}
        <router-link
          to="/login/verify"
          style="text-decoration: underline"
          class="link-bottom"
          >{{ $t("login.link2") }}</router-link
        >
      </div>
      <div class="container-buttom-text">
        {{ $t("login.msg3") }}
        <router-link
          to="/login/forgot"
          style="text-decoration: underline"
          class="link-bottom"
          >{{ $t("login.link3") }}</router-link
        >
      </div>
    </b-container>
    <IscModalAlerts
      v-model="showAlert"
      :icon="icon"
      :iconColor="iconColor"
      :title="title"
      :paragraph="paragraph"
      :buttonMessage="buttonMessage"
      @sendResult="resultModalAlerts"
      :noCloseBackdrop="true"
    >
    </IscModalAlerts>
    <CpDoubleBtnModal
      v-model="isShowModalBtn"
      :title="titleModalBtn"
      :paragraph="paragraphModalBtn"
      btnMsg1="No"
      btnMsg2="Yes"
      @send-result-btn1="closeModalSession"
      @send-result-btn2="setFncSession"
    />
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
//import { jwtDecode } from "jwt-decode"; maybe needed in future
import {
  mapActions,
  AlertSetting,
  IscModalAlerts,
  Helpers,
  CpDoubleBtnModal,
  DoubleBtnMixin
} from "@isc/styleguide";

export default {
  name: "LoginContainer",
  mixins: [AlertSetting, DoubleBtnMixin],
  components: {
    IscModalAlerts,
    CpDoubleBtnModal
  },
  data() {
    return {
      idNameGroup: null,
      email: null,
      password: null,
      loginPressed: false,
      boolLoading: false,
      boolIngresando: false,
      pressbutton: false,
      showPassword: false,
      typeInput: "password",
      isLogInAgain: false,
      userInfo: null,
    };
  },
  async mounted() {
    if (window.innerWidth >= 768) {
      const heightContainer = window.innerHeight - 62;
      const $registerContainer = document.querySelector(
        ".container-login-inner"
      );
      $registerContainer.style.height = String(heightContainer) + "px";
    }
  },
  methods: {
    ...mapActions({
      getInfoUser: "settings/profile/GET_INFO_USER",
      getUserByEmail: "settings/profile/GET_USER_BY_EMAIL",
      setClient: "settings/profile/CREATE_CLIENT",
      setUpdateUserByLogin: "settings/profile/UPDATE_USER_BY_LOGIN",
      setConsumePlanCLient: "global/iscPlans/ADD_CONSUME_LIMITS_CLIENT",
      signInUser: "login/auth/SIGN_IN_USER",
      getInfoClient: "settings/profile/GET_INFO_CLIENT_BY_ID",
      createSession: "login/session/SESSION_CREATE",
      reAuthSession: "login/session/SESSION_RE_AUTH",
    }),
    //* MAIN FUNCTION LOGIN
    async login() {
      this.boolLoading = true;
      this.loginPressed = true;

      if (this.$v.$invalid) {
        this.boolLoading = false;
        return;
      }

      if (!this.pressbutton) {
        try {
          this.pressbutton = true;
          localStorage.clear();
          sessionStorage.clear();
          this.email = this.email.trim();
          this.idNameGroup = this.idNameGroup.trim();

          //* Get info user
          const data = { email: this.email };
          const userData = await this.getUserByEmail(data);
          if (!userData.data) {
            this.errorModal(
              "Email address may be incorrect or disabled. Please verify and try again."
            );
            this.pressbutton = false;
            this.boolLoading = false;
            this.loginPressed = false;
            return;
          }
          let sessionId = userData.data.session_id;
          let expirationToken = userData.data.expiration_token;
          this.userInfo = Helpers.simpleOmitObj(userData.data, [
            "session_id",
            "expiration_token"
          ]);
          //console.log("USER INFO ==>", this.userInfo);
          //console.log("SESSION ID ==>", sessionId);
          //Todo: create a new session
          if (!sessionId) {
            await this.processLoginAndSettingValues(this.userInfo, true);
            return
          } 
          // cast expiration token in unixTime, and also now()
          // expiration_token 12:21 < 12:25
          const currentDateUnix = Helpers.getCurrentDateUtcUnixTime();
          const expirationTokenUnix = Helpers.convertUtcTimestampToUnixTime(
            expirationToken
          );

          //* Update the session and  create a new one
          if (expirationTokenUnix <= currentDateUnix) {
            //console.log("ENTER expirationTokenUnix <= currentDateUnix");
            //console.log(expirationTokenUnix, currentDateUnix);
            await this.processLoginAndSettingValues(this.userInfo, false);
            return;
          } 

          /* 
            Show modal:
            1) Session exists and expiration_token > now() => show modal to re-authenticate
            Modal: if the client accepts update and create a new session.
          */
          this.showModalBtn(
            "This user is already logged in",
            "Would you like to log in again?"
          );
          this.isLogInAgain = true;
        } catch (error) {
          this.errorModal(error.response.data.message);
          this.setButtonLoginBoolean();
        }
      }
    },

    async processLoginAndSettingValues(userInfo, isCreateNewSession) {
      try {
        const {
          data: { token, refreshToken }
        } = await this.signInUser({
          email: this.email,
          password: this.password,
          idGroup: this.idNameGroup
        });

        //* Important: Set to into localStorage User'data

        //localStorage.setItem("employeeUsername", userInfo.DSC_USERNAME);
        //localStorage.setItem("clientId", clientId);
        //localStorage.setItem("token", token);
        //localStorage.setItem("refreshToken", refreshToken);

        //sessionStorage.setItem("employeeUsername", userInfo.DSC_USERNAME);
        //sessionStorage.setItem("clientId", clientId);
        sessionStorage.setItem("token", token);
        sessionStorage.setItem("refreshToken", refreshToken);

        //console.log("USER INFO ==> ", userInfo);
        //* Set Ips
        await this.getsetLocalStorageIpPublicAndPrivate();

        let id_cliente = userInfo.id_cliente;
        // UPDATING CLIENT INTO TABLE 'T_USUARIO'
        // IF id_cliente == null because it is a CLIENT not a USUARIO
        if (!id_cliente) {
          //console.log("CREATE CLIENT TO TABLE 'M_CLIENTE'");
          userInfo.ID_PAIS = 1;

          // Create client to monitor schema, table: M_CLIENT
          const respCreateClient = await this.setClient(userInfo);
          id_cliente = respCreateClient.data.obj.id_cliente;
          //* Update client, table T_USUARIO, add id_cliente and flag_session 1
          // we send with  flg_inicio_sesion = null because it is its first login
          const dataUserByLogin = {
            DSC_USERNAME: userInfo.DSC_USERNAME,
            id_cliente,
            FLG_INICIO_SESION: null
          };
          await this.setUpdateUserByLogin(dataUserByLogin);

          //*Todo: create all type consume to client (Isc Plans)
          await this.setConsumePlanCLient(id_cliente);
        }
        // create or reauthenticate a session 
        if (isCreateNewSession) {
          await this.createSession();
        } else {
          await this.reAuthSession();
        }
        // call all data from CLIENTE from T_USUARIO by ID
        const {
          data: { obj }
        } = await this.getInfoClient(id_cliente);
        if (!obj) {
          this.errorModal(
            "Client not found, please send a email to support@isocialcube.com"
          );
          return;
        }
        // remove when we finish to store for each project
        localStorage.setItem("username", obj.DSC_USERNAME);
        sessionStorage.setItem("username", obj.DSC_USERNAME);

        //localStorage.setItem("userObj", JSON.stringify(obj));
        sessionStorage.setItem("userObj", JSON.stringify(obj));
        
        //* Update the date of the last login
        //* comment this code because we use session table and control login and logout dates.
        /* const dataUserByLogin = {
          DSC_USERNAME: obj.DSC_USERNAME,
          id_cliente: id_cliente,
          FLG_INICIO_SESION: obj.FLG_INICIO_SESION
        };
        await this.setUpdateUserByLogin(dataUserByLogin); */

        this.boolLoading = false;
        this.boolIngresando = true;
        //* comment if you do not want to redirect to HOME
        window.location.href = "/settings/home";
      } catch (error) {
        this.errorModal(error.response.data.message);
        this.pressbutton = false;
        this.boolLoading = false;
        this.boolIngresando = false;
      }
    },
    async getsetLocalStorageIpPublicAndPrivate() {
      try {
        const public_ip = require("public-ip");
        const local_ip = require("ip");

        let respIp = await public_ip.v4();
        let ipLocal = local_ip.address();
        // localStorage.setItem("ip-public", respIp);
        // localStorage.setItem("ip-local", ipLocal);
        sessionStorage.setItem("ip-public", respIp);
        sessionStorage.setItem("ip-local", ipLocal);

        return true;
      } catch (error) {
        return false;
      }
    },
    viewPassword() {
      this.showPassword = !this.showPassword;
      this.typeInput = this.showPassword ? "text" : "password";
    },
    setButtonLoginBoolean() {
      this.pressbutton = false;
      this.boolLoading = false;
      this.boolIngresando = false;
    },
    //* MODAL FUNCTION
    async setFncSession() {
      this.isShowModalBtn = false;
      if (this.isLogInAgain) {
        this.isLogInAgain = false;
        // Update and Create a new session.
        await this.processLoginAndSettingValues(this.userInfo, false);
      }
    },
    closeModalSession() {
      this.isShowModalBtn = false;
      this.isLogInAgain = false;
      this.setButtonLoginBoolean();
    },
  },
  validations: {
    idNameGroup: { required },
    email: { required },
    password: { required }
  }
};
</script>
<style scoped>
.container-login-inner {
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.title-login {
  font-size: 52.5px;
  text-align: center;
  font-weight: 200;
}
.subtitle-login {
  font-size: 16px;
  font-weight: lighter;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}
.input-text {
  width: 50%;
  margin-left: 25%;
  outline: none;
  border: 0;
  border-bottom: 1px solid rgb(137, 137, 137);
  font-size: 14px;
  padding: 15px 25px;
}
.button-container {
  text-align: center;
}
.button-login {
  width: 50%;
  margin-top: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
  color: white;
  background-color: rgb(0, 206, 193);
  border: none;
  border-radius: 8px;
  margin-bottom: 10px;
}
.button-login:hover {
  background-color: rgb(3, 189, 177);
}
.button-loading {
  width: 50%;
  margin-top: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
  color: white;
  background-color: #f44;
  border: none;
  border-radius: 50px;
  margin-bottom: 10px;
}
.button-loading:hover {
  background-color: #f44;
}
.container-buttom-text {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 200;
  text-align: center;
}
.link-bottom {
  color: black;
}
.error {
  margin-top: 5px;
  text-align: center;
  font-size: 12px;
  color: #f44336;
}
.eye-password {
  position: absolute;
  padding: 15px 25px;
  right: 24%;
  font-size: 16px;
  color: #a2acc4;
  cursor: pointer;
}
.input-login {
  position: relative;
}
</style>
