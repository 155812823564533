<template>
  <b-row no-gutters>
    <b-col cols="8" class="mt-2 pl-3 pr-5">
      <div class="mb-4 d-flex align-items-center">
        <span class="title-style">New Likes per Day </span>
        <i
          class="fa fa-info-circle icoPop"
          v-b-popover.hover="{
            variant: 'primary',
            content: 'Fans Trend',
          }"
        ></i>
      </div>
      <div
        v-if="!boolViewData"
        class="image-container-off">
        <img
          src="@/assets/images/image-off-line-graph.png"
          style="opacity: 0.5; width: 100%"
          alt=""
        />
        <div class="image-container-off__message">
          <div>{{ titleImgOff }}</div>
          {{ msgImgOff }}
        </div>
      </div>
      <highcharts v-else :options="getOptions()" />
    </b-col>
    <b-col
      cols="4"
      class="pl-5 pr-0 pt-2 mt-5 d-flex justify-content-between align-items-start panels flex-wrap box-column-trend"
    >
      <div
        class="bg-facebook h-24 rounded d-flex flex-column justify-content-center align-items-center"
      >
        <p class="white-color-text font-weight-bold text-center">New Likes</p>
        <p class="bx_Content_color text-white text-center">
          <span class="font-weight-bold">
            {{ numFormatter(dataList.likes) }}
          </span>
          <i class="fa fa-sort-up text-white"></i>
        </p>
      </div>

      <div
        class="bg-warning h-24 rounded d-flex flex-column justify-content-center align-items-center"
      >
        <p class="white-color-text font-weight-bold text-center">Unlikes</p>
        <p class="bx_Content_color text-white text-center">
          <span class="font-weight-bold">
            {{ numFormatter(dataList.unlikes) }}
          </span>
          <i class="fa fa-sort-up text-white"></i>
        </p>
      </div>

      <div>
        <p class="text-trend text-center mb-3">Growth</p>
        <vc-donut
          :sections="[{ value: dataList.growth, color: '#0a2aee' }]"
          :size="115"
          :thickness="10"
          :auto-adjust-text-size="true"
        >
          <span class="text-donut">{{ dataList.growth }}%</span>
        </vc-donut>
      </div>

      <div>
        <p class="text-trend text-center mb-3">Fans Lost per day</p>
        <vc-donut
          :sections="[{ value: dataList.lost, color: '#0a2aee' }]"
          :size="115"
          :thickness="10"
          :auto-adjust-text-size="true"
        >
          <span class="text-donut">{{ dataList.lost }}%</span>
        </vc-donut>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { rutaNotFound } from "../../../../../../util/config";
import { Helpers, mapGetters } from "@isc/styleguide";

export default {
  data() {
    return {
      rutaNotFoundAny: rutaNotFound,
    };
  },
  computed: {
    ...mapGetters({
      msgImgOff: "global/helper/getImageOffGraphic",
      titleImgOff: "global/helper/getTitleImgOff",
    }),
  },
  props: {
    series: {
      type: Array,
      required: true,
    },
    dataList: {
      type: Object,
      required: true,
    },
    chartCategories: {
      type: Array,
      required: true,
    },
    boolViewData: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    getOptions() {
      try {
        let options = {
          chart: {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            type: "spline",
            marginTop: 10,
            height: 360,
            marginBottom: 70,
          },
          title: { text: "", enabled: false },
          credits: { text: "" },
          xAxis: {
            title: { text: "Days", align: "middle" },
            lineColor: "#D6DBDF",
            tickLength: 0,
            type: "datetime",
            categories: [],
            labels: {
              formatter: function () {
                return this.value;
              },
              rotation: 0,
              autoRotation: false,
              align: "center",
              style: { color: "#999999" },
            },
            dateTimeLabelFormats: {
              day: "%e of %b",
            },
          },
          yAxis: [
            {
              allowDecimals: false,
              title: { text: "New Likes", align: "middle" },
              min: null,
              labels: { style: { color: "#B3B4B8" } },
            },
            {
              allowDecimals: false,
              gridLineColor: "#D5DAE0",
              title: { enabled: true, text: "Unlikes", align: "middle" },
              min: null,
              labels: { style: { color: "#B3B4B8" } },
              opposite: true,
            },
          ],
          plotOptions: {
            line: {
              marker: {
                enabled: true, // muestra el punto
              },
              connectNulls: true,
            },
            series: {
              pointWidth: 4, //width of the column bars irrespective of the chart size
              allowPointSelect: true,
            },
            spline: { marker: { lineWidth: 1, radius: 3, symbol: "circle" } },
            areaspline: {
              marker: { lineWidth: 1, radius: 3, symbol: "circle" },
            },
          },
          tooltip: {
            formatter: function () {
              let text =
                "<table class='table_tooltip' border=0 cellspacing=0 cellpadding='5px' style=''>";
              text +=
                "<tr><td class='tp-table' colspan='2' style=''>" +
                this.x +
                "</td></tr>";
              for (let i = 0; i < this.points.length; i++) {
                text += "<tr><td style='padding:4px;padding-left:8px;'>";
                text +=
                  "<i class='fa fa-circle' style='margin-right:4px;vertical-align:middle;font-size:6px;color:" +
                  this.points[i].series.color +
                  " !important'></i> " +
                  this.points[i].series.name;
                text +=
                  "</td><td style='padding:4px;text-align:right;padding-right:8px;font-weight:bold;'>";
                text += Helpers.numberWithCommas(this.points[i].y);
                text += "</td></tr>";
              }
              text += "</table>";
              return text;
            },
            shared: true,
            padding: 0,
            backgroundColor: "#ffffff",
            useHTML: true,
            shadow: false,
            borderWidth: 0,
            borderRadius: 0,
            crosshairs: true,
          },

          exporting: { enabled: false },
          labels: {
            items: [
              {
                style: { left: "40px", top: "8px", color: "#ACADAF" },
              },
            ],
          },
          legend: {
            align: "right",
            verticalAlign: "bottom",
            y: 10,
            floating: true,
            borderWidth: 0,
            shadow: false,
            symbolPadding: 10,
            symbolWidth: 5,
          },
          series: [
            {
              yAxis: 0,
              type: "spline",
              color: "#1877f2",
              name: "New Likes",
              data: [],
            },
            {
              yAxis: 0,
              type: "spline",
              color: "#ffbe28",
              name: "Unlikes",
              data: [],
            },
          ],
        };
        options.series = this.series;
        options.xAxis.categories = this.chartCategories;
        return options;
      } catch (error) {
        return {};
      }
    },
    numFormatter(num) {
      if (num > 999 && num < 1000000) {
        num = (num / 1000).toFixed(2) + "K"; // convert to K for number from > 1000 < 1 million
      } else if (num > 1000000) {
        num = (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
      } else if (num < 900) {
        num; // if value < 1000, nothing to do
      }
      return num;
    },
  },
  created() {},
};
</script>

<style scoped>
.panels > div {
  width: 45%;
}
.off_op {
  opacity: 0.5;
}
</style>
