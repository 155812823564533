<template>
  <div>
    <router-view></router-view>
    <cp-double-btn-modal
      v-model="isShowModalBtn"
      :title="titleModalBtn"
      :paragraph="paragraphModalBtn"
      btnMsg1="No"
      btnMsg2="Yes"
      :showBtn="isShowBtn"
      @send-result-btn1="redirectToLogin"
      @send-result-btn2="setTokenAndSession"
    />
    <!-- Modal no auth, example of validate session or token -->
    <cp-isc-modal-no-auth />
  </div>
</template>
<script>
import {
  CpDoubleBtnModal,
  DoubleBtnMixin,
  ExpTokenMixin,
  CpIscModalNoAuth
} from "@isc/styleguide";

export default {
  name: "App",
  components: {
    CpDoubleBtnModal,
    CpIscModalNoAuth
  },
  mixins: [DoubleBtnMixin, ExpTokenMixin],
  methods: {},
};
</script>
<style>
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1080px !important;
  }
}

.modal-contant {
  padding: 20px 20px 0 80px !important;
}
/* estilo de transition general */
.slide-leave-active,
.slide-enter-active {
  transition: 0.5s;
}
.slide-enter {
  transform: translate(-100%, 0);
}
.slide-leave-to {
  transform: translate(100%, 0);
}

.modal .close {
  font-size: 35px;
  font-weight: 900;
}
</style>
