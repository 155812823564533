import axios from "axios";
// overview back
const base_url = require('../util/config').rutaService
const respUserSession = require('../util/util').getObjUsuarioSession() || {}
const { validationCodeToken } = require('../util/validateCodeToken')
import { getCredentialsHeader } from "../util/util";

export const listAccountsByClient = async (page) => {
  try {
    const client_id = respUserSession.id_cliente || 1; // TODO : esto será llamado desde el localstorage junto con el token
    let jsonAxios = {
      method: 'get',
      url: `${base_url}/accounts/${client_id}/client`,
      headers: getCredentialsHeader(),
      params: {
        page: page
      }
    }
    const { data } = await axios(jsonAxios);
    return validationCodeToken(data);   
  } catch (error) {
    console.error('el error : ',error)
    console.error('el respUserSession : ',respUserSession)
    return -1;
  }
};
export const expiredAccounts = async () => {
  try {
    const client_id = respUserSession.id_cliente || 1;
    let jsonAxios = {
      method: 'get',
      url: `${base_url}/accounts/${client_id}/token`,
      headers: getCredentialsHeader(),
    } 
    const { data } = await axios(jsonAxios);
    return validationCodeToken(data);
  } catch (error) {
    return error.response;
  }

}

export const ListAccounts = async () => {
  try {
    const client_id = respUserSession.id_cliente || 1;
    let jsonAxios = {
      method: 'get',
      url: `${base_url}/accounts/ListAccounts/`,
      headers: getCredentialsHeader(),
      params: {
        idcliente: client_id
      }
    }
    const { data } = await axios(jsonAxios);
    return validationCodeToken(data);
  } catch (error) {
    return error.response;
  }
}

/* 
  * Validate if the account has its token flg_estado = 1
  * @async
  * @return {Array}
*/
export const getValidateAccountBySN = async (socialNetworkId) => {
  try {
    const client_id = respUserSession.id_cliente;
    let jsonAxios = {
      method: 'post',
      url: `${base_url}/accounts/validate-account`,
      headers: getCredentialsHeader(),
      data: {
        socialNetworkId,
        clientId: client_id
      }
    }
    const { data } = await axios(jsonAxios);
    return validationCodeToken(data);
  } catch (error) {
    return error.response;
  }
}
/* 
  * Validate if the account has its token flg_estado = 1
  * @async
  * @return {Array}
*/
export const getValidationLimitAccountBySN = async (socialNetworkId, idModulo) => {
  try {
    const client_id = respUserSession.id_cliente;
    let jsonAxios = {
      method: 'post',
      url: `${base_url}/accounts/restriction-account`,
      headers: getCredentialsHeader(),
      data: {
        socialNetworkId,
        clientId: client_id,
        idModulo
      }
    }
    const { data } = await axios(jsonAxios);
    return validationCodeToken(data);
  } catch (error) {
    return error.response;
  }
}