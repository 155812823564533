export const codeToken = {
  data() {
    return {
      showAlertCode: false,
      iconCode: "",
      iconColorCode: "",
      titleCode: "",
      paragraphCode: "",
      buttonMessageCode: "",
      //isTokenCode1003: false
    };
  },
  created() {},
  methods: {
    validationCodeToken(data) {
      try {
        if (data.code === 1002) {
          this.errorModalCodeToken("Token expired", "The token has expired, we will redirect to the login.")
        } else if (data.code === 1001) {
            this.errorModalCodeToken("Token invalid", "The token is invalid, we will redirect to the login");
        } else if (data.code === 1003) {
          //1003 NECESITA HACER REFRESH
          const newToken = data.newToken;
          sessionStorage.setItem("token", newToken);
          //this.isTokenCode1003 = true;
          //this.errorModalCodeToken("Token expired", " It will change with a new token, Please try again it.");
          return data.data;
        } else if (data.code === 1005) {
          //1005 EXISTE OTRA SESION ACTIVA
          this.errorModalCodeToken( "Active session", "There is an active session, please re-authenticate")
        } else {
          return data.data;
        }
      } catch (error) {
        console.error(
         "[Error] in function: validationCodeToken",
          error
        );
      }
    },
    resultModalAlertsCode(value) {
      this.showAlertCode = value;
      //if(!this.isTokenCode1003) {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = "/login";
      // } else {
      //   this.isTokenCode1003 = false;
      // }
    },
    modalMessageParamsCode(icon, iconColor, title, paragraph, buttonMessage) {
      this.showAlertCode = true;
      this.iconCode = icon;
      this.iconColorCode = iconColor;
      this.titleCode = title;
      this.paragraphCode = paragraph;
      this.buttonMessageCode = buttonMessage;
    },
    errorModalCodeToken(titleError, message) {
      let paragraph = message;
      let title = titleError ?? "Error";
      this.modalMessageParamsCode(
        "fa fa-times-circle",
        "#FF0000",
        title,
        paragraph,
        "Ok"
      );
    },
  },
};
