import Router from "vue-router";
import App from "@/App";

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "/overview/accounts",
    },
    {
      path: "/auth",
      name: "Token",
      component: App
    },
  ],
});
