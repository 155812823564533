<template>
  <div>
    <loader-custom v-if="visibilityLoader === true"></loader-custom>
    <div class="row mt-4">
      <div
        class="col-3 px-2"
        v-for="(itemsa, index) in campaignsDet"
        :key="itemsa.id_campania"
      >
        <div class="d-flex rounded-6 flex-column mb-3">
          <div class="position-relative cursor-pointer">
            <div
              @click="
                visualizar(
                  itemsa.id_campania,
                  itemsa.fec_inicio_campania,
                  itemsa.fec_fin_campania
                )
              "
              class="position-absolute w-100 h-100 d-flex justify-content-center align-items-center eye z-20 rounded-top-6"
            >
              <i class="far fa-eye"></i>
            </div>
            <div
              :class="[
                'p-3 d-flex flex-column justify-content-between z-10 text-white rounded-top-6 campaign-box-item-center',
                itemsa.flgEstado ? 'bg-primary' : 'bg-danger',
              ]"
            >
              <div class="">
                <span class="text-lsm font-weight-bold">
                  {{ itemsa.nom_campania }}
                </span>
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <p class="text-3-5xl font-weight-bold">
                    {{ itemsa.totalnew }}
                  </p>
                  <p
                    v-if="itemsa.descripcionmetrica == 'Leads'"
                    class="text-xs"
                  >
                    Addon Leads
                  </p>
                  <p
                    v-if="itemsa.descripcionmetrica == 'Audiencie'"
                    class="text-xs"
                  >
                   New Audience
                  </p>
                </div>
                <div
                  @change="
                    consultaPost(
                      itemsa.id_campania,
                      itemsa.fec_inicio_campania,
                      itemsa.fec_fin_campania
                    )
                  "
                >
                  <div>
                    <span v-for="(itemsRed, index) in itemsa.rss" :key="index">
                      <i
                        v-if="itemsRed == 1"
                        class="mr-2 text-sm fab fa-facebook texticon-facebook"
                      ></i>
                      <i
                        v-if="itemsRed == 4"
                        class="mr-2 text-sm fab fa-youtube texticon-youtube"
                      ></i>
                      <i
                        v-if="itemsRed == 3"
                        class="mr-2 text-sm fab fa-instagram texticon-instagram"
                      ></i>
                      <i
                        v-if="itemsRed == 2"
                        class="mr-2 text-sm fab fa-twitter texticon-twitter"
                      ></i>
                      <i
                        v-if="itemsRed == 5"
                        class="mr-2 text-sm fab fa-linkedin texticon-linkedin"
                      ></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="text-center mt-3">
                <p v-if="itemsa.flgEstado == 1"></p>
                <p
                  v-if="itemsa.flgEstado == 0"
                  class="text-lsm font-weight-bold"
                >
                  FINISHED
                </p>
              </div>
              <div class="text-center text-xs">
                {{ itemsa.marcas }}
              </div>
            </div>
          </div>
          <div
            class="d-flex justify-content-between bg-white text-center text-xs p-3 border-bottom"
          >
            <div>
              <p class="font-weight-bold text-xs text-color-text">Start</p>
              <p class="text-xs text-color-text">
                {{
                  transformDate(
                    itemsa.fec_inicio_campania,
                    userInfo.TIME_ZONE,
                    "DD-MM-YYYY"
                  )
                }}
              </p>
            </div>
            <div>
              <p class="font-weight-bold text-xs text-color-text">End</p>
              <p class="text-xs text-color-text">
                {{
                  transformDate(
                    itemsa.fec_fin_campania,
                    userInfo.TIME_ZONE,
                    "DD-MM-YYYY"
                  )
                }}
              </p>
            </div>
            <div>
              <p class="font-weight-bold text-xs text-color-text">Status</p>
              <p class="text-xs text-color-text" v-if="itemsa.flgEstado == 1">
                Active
              </p>
              <p class="text-xs text-color-text" v-if="itemsa.flgEstado == 0">
                Inactive
              </p>
            </div>
          </div>
          <div
            class="d-flex justify-content-between bg-white text-center text-xs p-3"
          >
            <div>
              <p class="font-weight-bold text-xs text-color-text">Actions</p>
              <p class="text-xs text-color-text">{{ itemsa.totalactions }}</p>
            </div>
            <div>
              <p id="type" class="font-weight-bold text-xs text-color-text">
                Type
              </p>

              <p class="text-xs text-color-text">
                {{ itemsa.descripcionmetrica === 'Audiencie' ? 'Audience' : itemsa.descripcionmetrica }}
              </p>
            </div>
            <div class="d-flex flex-center">
              <i
                v-if="itemsa.flgEstado == 1"
                class="circulo-verde"
                :id="`circulo-verde-${index}`"
              ></i>
              <CpTooltipIconCustom
                :target="`circulo-verde-${index}`"
                title="Active Campaign"
                messageTitle="Active Campaign"
                messageSubTitle="Data updated every 30 minutes."
                colorIcon="#9fd895"
              >
              </CpTooltipIconCustom>

              <i
                v-if="itemsa.flgEstado == 0"
                class="circulo-rojo"
                :id="`circulo-rojo-${index}`"
              ></i>
              <CpTooltipIconCustom
                :target="`circulo-rojo-${index}`"
                title="Finished Campaign"
                messageTitle="Finished Campaign"
                messageSubTitle="Data updated every 24 hours."
                colorIcon="#ff7e7e"
              >
              </CpTooltipIconCustom>
            </div>
          </div>
          <div
            :class="[
              'p-1 bg-white rounded-bottom button-list',
              itemsa.flgEstado ? 'flex-around' : 'flex-center',
            ]"
          >
            <button
              class="mr-2"
              @click="
                visualizar(
                  itemsa.id_campania,
                  itemsa.fec_inicio_campania,
                  itemsa.fec_fin_campania
                )
              "
            >
              <i class="text-lg text-color-text fas fa-eye"></i>
            </button>

            <router-link
              v-if="itemsa.flgEstado"
              :to="{
                name: 'edit-campaign',
                params: { key: itemsa.id_campania },
              }"
            >
              <i class="text-lg text-color-text fas fa-edit"></i>
            </router-link>
            
            <a class="link--disabled" v-else><i class="text-lg fas fa-edit"></i></a>

            <button class="ml-2">
              <i
                class="text-lg text-color-text fas fa-trash-alt"
                @click="
                  openModalDelete(
                    itemsa.id_campania,
                    itemsa.flgEstado,
                    itemsa.nom_campania
                  )
                "
              ></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div>
      <!-- ALERT CODE MODAL -->
      <IscModalAlerts
        v-model="showAlertCode"
        :icon="iconCode"
        :iconColor="iconColorCode"
        :title="titleCode"
        :paragraph="paragraphCode"
        :buttonMessage="buttonMessageCode"
        @sendResult="resultModalAlertsCode"
        :noCloseBackdrop="true"
        :refresh="true"
      />
      <!-- MODAL ALERT GLOBAL -->
      <IscModalAlerts
        v-model="showAlert"
        :icon="icon"
        :iconColor="iconColor"
        :title="title"
        :paragraph="paragraph"
        :buttonMessage="buttonMessage"
        :refresh="true"
        @sendResult="resultModalAlerts"
        :noCloseBackdrop="true"
        @input="closeIconModal"
      />
    </div>
  </div>
</template>
<script>
import {
  CpTooltipIconCustom,
  IscModalAlerts,
  loaderCustom,
  Helpers,
  mapGetters,
  mapActions,
  AlertSetting,
  CodeToken,
} from "@isc/styleguide";
import * as RestrictService from "@/services/restrict.service";
export default {
  name: "CampaingBox",
  components: {
    CpTooltipIconCustom,
    IscModalAlerts,
    loaderCustom,
  },
  mixins: [AlertSetting, CodeToken],
  props: {
    campaignsDet: {
      type: Array,
      default: () => {
        return [];
      },
    },
    redSocCamps: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      Data: [],
      showAlert: false,
      icon: "",
      iconColor: "",
      title: "",
      paragraph: "",
      buttonMessage: "",
      idTwitter: Number(process.env.VUE_APP_TWITTER),
      campaignPostModuleTwId:
        process.env.VUE_APP_TWITTER_MODULO_REGISTER_CAMPAIGN,
      visibilityLoader: false,
      transformDate: (date, countryTz, format) =>
        Helpers.transformStringDate(date, countryTz, format),
      idCampaign: 0,
      isActiveCampaign: false,
      isDeleteCampaign: false,
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "settings/profile/getObjUser",
    }),
  },
  methods: {
    ...mapActions({
      removeCampaign: "settings/monitor/DELETE_CAMPAIGN",
    }),
    async visualizar(idCampaign, fec_ini, fec_fin) {
      this.$router
        .push({
          name: "campaign-detail",
          query: { campaign: idCampaign, fec_ini, fec_fin },
        })
        .catch((_) => {});
      /*  window.location.href =
        "/campaign?campaign=" +
        idCampaign +
        "&fec_ini=" +
        fec_ini +
        "&fec_fin=" +
        fec_fin; */
    },
    openModalDelete(id_campania, isActiveCampaign, nameCampaign) {
      this.idCampaign = id_campania;
      this.isActiveCampaign = isActiveCampaign;
      this.warningModal(
        `Are you sure to delete this campaign "${nameCampaign}"?`
      );
      this.isDeleteCampaign = true;
    },
    async deleteCampaign() {
      try {
        this.visibilityLoader = true;
        const data = {
          campaignId: this.idCampaign,
          statusCampaign: this.isActiveCampaign,
          clientId: this.userInfo.id_cliente,
        };
        const respDeleteCampaign = await this.removeCampaign(data);
        const resp = this.validationCodeToken(respDeleteCampaign.data);
        //this validation is for the function validateCodeToken()
        if (!resp) return;
        //console.log("Response ", resp );
        if (resp.status == 200 && resp.result.length > 0) {
          //* Update restrict limit consume by client if flgEstado = 1; Active Campaign
          if (this.isActiveCampaign) {
            await this.updateConsumeClientByModuleNoPeriodSN(this.idCampaign);
          }
          this.successModal("Deleted campaign");
        } else {
          this.errorModal("Campaign was not deleted.");
        }
      } catch (error) {
        console.log("[Error] in [Main Function] deleteCampaign ", error);
        let msg =
          error?.response?.data?.message ??
          "Server fails to delete a campaign.";
        this.errorModal(msg);
      } finally {
        this.visibilityLoader = false;
      }
    },
    updateDetail(updateList) {
      if (updateList) {
        this.$emit("updateDetail", true);
      }
    },
    async resultModalAlerts(value) {
      this.showAlert = value;
      if (this.isDeleteCampaign) {
        await this.deleteCampaign();
        this.$emit("updateDetail", true);
        this.isDeleteCampaign = false;
      }
    },
    closeIconModal() {
      this.isDeleteCampaign = false;
    },
    //* METHODS TO UPDATE CONSUME LIMIT CLIENT
    //Main function
    async updateConsumeClientByModuleNoPeriodSN(idCampaign) {
      // order all posts depending of social network
      const arrConsumePosts = await this.orderHistoryConsumeCampaignsBySN(
        idCampaign
      );
      //console.log("Array consume Posts", arrConsumePosts);
      if (arrConsumePosts.length === 0) return;
      for (const consumePost of arrConsumePosts) {
        if (consumePost.twitter.length > 0) {
          // function to update
          await this.updatePeriodConsumeClient(
            this.idTwitter,
            this.campaignPostModuleTwId,
            consumePost.twitter.length
          );
        }
      }
    },
    async updatePeriodConsumeClient(socialNetworkId, moduleId, currentConsume) {
      // calling module limit
      const clientConsume = await this.getConsumeClientNoPeriodDate(
        socialNetworkId,
        moduleId
      );
      const newAmountConsume = clientConsume.numConsumo - currentConsume;
      const amountSumActions = clientConsume.numSumaAcciones + 1;
      // update this module limit
      if (newAmountConsume >= 0) {
        await this.updateConsumePeriodClient(
          newAmountConsume,
          amountSumActions,
          moduleId
        );
      } else {
        let msg = `the quantity of numConsumo: ${clientConsume.numConsumo} does not subtract correctly with the quantity of Array posts: ${currentConsume}.`;
        this.errorModal(msg);
      }
    },
    //* SN: Social network
    async orderHistoryConsumeCampaignsBySN(idCampania) {
      const arrPostsBySN = [];
      const historiesPostsSN = await this.getServiceConsumeHistoryByCampaignId(
        idCampania
      );
      // No data retrieving
      if (historiesPostsSN.length === 0) return arrPostsBySN;
      //Declare variable socialNetwork
      arrPostsBySN[0] = { twitter: [] };
      for (const historyPost of historiesPostsSN) {
        // store data Twitter
        if (historyPost.idRedSocial === this.idTwitter) {
          arrPostsBySN[0].twitter.push(historyPost);
          //continue;
        }
        // add here more social Networks
        // Uncomment continue if you are going to add more social networks
      }

      return arrPostsBySN;
    },
    // SERVICES
    async getServiceConsumeHistoryByCampaignId(idCampania) {
      const resp = await RestrictService.getConsumeHistoryByCampaignId(
        idCampania
      );
      // validation Error
      if (resp && resp.status) {
        let msg =
          resp?.data?.message ??
          "Server fails to get a service consume history by channel id.";
        this.errorModal(msg);
      }
      return resp.data;
    },
    async getConsumeClientNoPeriodDate(socialNetworkId, idModule) {
      const resp = await RestrictService.getValidationLimitAccountBySN(
        socialNetworkId,
        idModule
      );
      // validation Error
      if (resp && resp.status) {
        let msg =
          resp?.data?.message ??
          "Server fails to get consume client no period date.";
        this.errorModal(msg);
      }
      return resp.data;
    },
    async updateConsumePeriodClient(numConsumo, numSumaAcciones, moduleID) {
      const data = {
        numConsumo,
        numSumaAcciones,
      };
      const resp = await RestrictService.updateRestrictAccount(data, moduleID);
      // validation Error
      if (resp && resp.status) {
        let msg =
          resp?.data?.message ??
          "Server fails to update consume period client.";
        this.errorModal(msg);
      }
      return resp.data;
    },
  },
};
</script>
<style scoped>
.box {
  min-width: 300px;
  width: 300px;
  margin: 19px 1px 15px 12px;
}

.eye {
  font-size: 100px;
  color: transparent;
  transition: all ease 0.5s;
}

.eye:hover {
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
}

.optionblock {
  width: 300px;
  text-align: center;
  margin: 16px 0px -15px 10px;
  padding: 0px 0px 0px 0px;
  color: var(--theme);
}

.button-list button,
.button-list a {
  min-width: 30%;
  border: none;
  border-radius: 0.2em;
  padding: 0.3em 0;
  color: var(--theme);
  transition: all ease 0.3s;
  text-align: center;
  background-color: var(--mainbg);
}

.button-list a.link--disabled {
  color: #c2c2c2;
  cursor: not-allowed;
}

.button-list button:hover,
.button-list a:hover {
  opacity: 0.8;
}

.circulo-verde {
  width: 15px;
  height: 15px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #9fd895;
}

.circulo-rojo {
  width: 15px;
  height: 15px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #ff7e7e;
}

p .action-detalle {
  display: none;
}

p:hover .action {
  display: none;
}

p:hover .action-detalle {
  display: inline;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.texticon-twitter {
  color: white;
}

.campaign-box-item-center {
  min-height: 164px;
}

@media (max-width: 1024px) {
  .campaign-box-item-center {
    min-height: 144px;
  }
}
</style>
