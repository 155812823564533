var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            title: "",
            "hide-footer": "",
            "no-enforce-focus": true,
            "no-close-on-backdrop": true
          },
          model: {
            value: _vm.model,
            callback: function($$v) {
              _vm.model = $$v
            },
            expression: "model"
          }
        },
        [
          _c("div", { staticClass: "container-alerts" }, [
            _vm.showSectionIcon
              ? _c("div", { staticClass: "flex-center" }, [
                  _vm.showMainIcon
                    ? _c("i", {
                        staticClass: "flex-center icon",
                        class: _vm.icon,
                        style: { color: _vm.iconColor }
                      })
                    : _c(
                        "div",
                        { staticClass: "spinner" },
                        [
                          _c("b-spinner", {
                            attrs: { variant: "primary", label: "Spinning" }
                          })
                        ],
                        1
                      )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("p", { staticClass: "container-alerts__title" }, [
              _c("strong", [_vm._v(_vm._s(_vm.title))])
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "container-alerts__paragraph",
                attrs: { "data-cy": "message-alert" }
              },
              [_vm._v("\n        " + _vm._s(_vm.paragraph) + "\n      ")]
            ),
            _vm._v(" "),
            _vm.showBtn
              ? _c(
                  "div",
                  {
                    staticClass: "container-alerts__buttons",
                    attrs: { "aria-hidden": "false" }
                  },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "px-3 border-1",
                        attrs: { variant: _vm.variantColorBtn1 },
                        on: { click: _vm.sendResultBtn1 }
                      },
                      [_vm._v(_vm._s(_vm.btnMsg1))]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        staticClass: "px-3",
                        attrs: { variant: _vm.variantColorBtn2 },
                        on: { click: _vm.sendResultBtn2 }
                      },
                      [_vm._v(_vm._s(_vm.btnMsg2))]
                    )
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "spinner container-alerts__spinner" },
                  [
                    _c("b-spinner", {
                      attrs: { variant: "primary", label: "Spinning" }
                    })
                  ],
                  1
                )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }