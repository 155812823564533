export default {
  getLoader: (state) => {
    return state.loader;
  },
  getImageOffGraphic: (state) => {
    return state.msgImgOffGraphic;
  }, 
  getImageOff: (state) => {
    return state.msgImgOff;
  }, 
  getTitleImgOff: (state) => {
    return state.titleImgOff;
  },
  getMsgNoAuth: (state) => {
    return state.msgNoAuth;
  },
}
