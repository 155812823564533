import './set-public-path'
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import singleSpaVue from 'single-spa-vue';
import { store, } from "@isc/styleguide";

Vue.config.productionTip = false;
const vueLifeCycles = singleSpaVue({
  Vue,
  appOptions: {
    render: (h) => h(App),
    el :"#authorization",
    router,
    store
  },
});

export const bootstrap = vueLifeCycles.bootstrap;
export const mount = vueLifeCycles.mount;
export const unmount = vueLifeCycles.unmount;
