import { handlerRespAxiosMonitorLogin } from "../../../../lib/handleResponse";

export default {
  //* POSTS OR UPDATES 
  async SESSION_CREATE(_) {
    try {
      return await handlerRespAxiosMonitorLogin("post", "session/create");
    } catch (error) {
      console.log("[Error] in function: SESSION_CREATE", error);
      throw error;
    }
  },
  async SESSION_LOGOUT(_) {
    try {
      return await handlerRespAxiosMonitorLogin("post", "session/logout");
    } catch (error) {
      console.log("[Error] in function: SESSION_LOGOUT", error);
      throw error;
    }
  },
  async SESSION_RE_AUTH(_) {
    try {
      return await handlerRespAxiosMonitorLogin("post", "session/re-auth");
    } catch (error) {
      console.log("[Error] in function: SESSION_RE_AUTH", error);
      throw error;
    }
  },
  async SESSION_UPDATE(_) {
    try {
      return await handlerRespAxiosMonitorLogin("post", "session/update");
    } catch (error) {
      console.log("[Error] in function: SESSION_UPDATE", error);
      throw error;
    }
  },
}