var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("isc-modal-alerts", {
        attrs: {
          title: _vm.title,
          paragraph: _vm.paragraph,
          icon: _vm.icon,
          iconColor: _vm.iconColor,
          showBtn: _vm.showBtn,
          showBtnClose: true,
          showMainIcon: _vm.showMainIcon,
          buttonMessage: _vm.buttonMessage,
          noCloseBackdrop: true
        },
        on: { sendResult: _vm.redirectToLogin, input: _vm.redirectToLogin },
        model: {
          value: _vm.isNoAuth,
          callback: function($$v) {
            _vm.isNoAuth = $$v
          },
          expression: "isNoAuth"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }