<template>
  <div id="actionComponent">
    <loader-custom v-if="visibilityLoader === true"></loader-custom>
    <b-container class="container-action">
      <b-row>
        <b-col>
          <div class="mb-5">
            <span
              class="font-weight-bold text-capitalize text-lsm text-secondary mr-1"
            >
              Campaign Results
            </span>
            <span class="text-secondary">
              <i
                id="titleProv"
                class="fa fa-info-circle text-sm text-skygray"
              ></i>
              <CpTooltipTableCustom
                target="titleProv"
                tooltipTitle="titleProv"
                :columns="columnsTooltipTable"
              />
            </span>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-row>
            <b-col cols="6" class="d-flex align-items-center pl-0">
              <span class="color-text text-xs mr-1">Channel:</span>
              <multiselect
                v-model="arrSocialNetworkSelected"
                :options="redSocial"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Select Channel"
                label="name"
                track-by="name"
                :preselect-first="true"
                @close="dispatchAction"
                class="selectSize"
                :deselectLabel="''"
                :selectLabel="''"
                :selectedLabel="''"
              >
                <template slot="selection" slot-scope="{ values, isOpen }">
                  <span
                    class="multiselect__single"
                    v-if="values.length &amp;&amp; !isOpen"
                    >{{
                      values.length !== redSocial.length
                        ? values.length + " options"
                        : "All Selected"
                    }}
                  </span>
                </template>
                <template slot="noOptions"><span>All selected</span></template>
                <template slot="tag">{{ "" }}</template>
              </multiselect>
            </b-col>
            <b-col cols="6" class="d-flex align-items-center pl-0">
              <span class="color-text text-xs mr-1">Actions:</span>
              <multiselect
                class="selectSize"
                v-model="arrActionsSelected"
                :options="actions"
                :multiple="true"
                :taggable="false"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Select action"
                label="name"
                track-by="name"
                :preselect-first="true"
                @close="selectActionMultiple"
                :selectLabel="''"
                :deselectLabel="''"
                :selectedLabel="''"
              >
                <template slot="selection" slot-scope="{ values, isOpen }"
                  ><span
                    class="multiselect__single"
                    v-if="values.length &amp;&amp; !isOpen"
                    >{{
                      values.length !== actions.length
                        ? values.length + " options"
                        : "All Selected"
                    }}</span
                  >
                </template>
                <template slot="tag">{{ "" }}</template>
              </multiselect>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <p class="impress title-style">Impressions</p>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col cols="6" class="pl-0 pr-0">
          <b-row>
            <div
              class="box-table-action w-100"
              v-if="tableactionsvisibility"
            >
              <div class="top-th">
                <div class="row">
                  <div class="block-1 col-4"></div>
                  <div class="block-2 col-4 text-center">
                    <span class="font-weight-bold text-secondary text-xs"
                      >Organic</span
                    >
                  </div>
                  <div class="block-3 col-4 text-center">
                    <span class="font-weight-bold text-secondary text-xs"
                      >Paid</span
                    >
                    <i id="allViewPaid" class="fa fa-info-circle iconPaid"></i>
                    <CpTooltipWhiteCustom
                      target="allViewPaid"
                      title="allViewPaid"
                      paddingContainer="10px 1px 10px 1px"
                      message="Paid campaigns only bring info from Facebook, Twitter and LinkedIn. The posts must appear on the accounts feed."
                    />
                  </div>
                </div>
                <!-- <span v-b-tooltip title="Impressions: This metric can only be obtained from Facebook and Instagram">name</span> -->
              </div>
              <div class="second-th table-head-bg">
                <div class="row">
                  <div class="col-4 p-0 border-tb-right">
                    <div class="row">
                      <div class="col-8"><span></span></div>
                      <div class="col-4 p-0 text-center">
                        <span class="font-weight-bold text-secondary text-xs"
                          >Sentim.</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-4 p-0 border-tb-right">
                    <div class="row">
                      <div class="col-4 p-0 text-center">
                        <span
                          class="font-weight-bold text-secondary text-xs"
                          v-b-tooltip
                          title="Impressions: This metric can only be obtained from Facebook. For YouTube and Instagram, views are considered as impressions."
                          >Impres.</span
                        >
                      </div>
                      <div class="col-4 p-0 text-center">
                        <span
                          class="font-weight-bold text-secondary text-xs"
                          v-b-tooltip
                          title="Reach: This metric can only be obtained from Facebook and Instagram"
                          style="text-align: center"
                          >Reach</span
                        >
                      </div>
                      <div class="col-4 p-0 text-center">
                        <span class="font-weight-bold text-secondary text-xs"
                          >Interac</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-4 p-0">
                    <div class="row">
                      <div class="col-4 p-0 text-center">
                        <span class="font-weight-bold text-secondary text-xs"
                          >Impres.</span
                        >
                      </div>
                      <div class="col-4 p-0 text-center">
                        <span class="font-weight-bold text-secondary text-xs"
                          >Reach</span
                        >
                      </div>
                      <div class="col-4 p-0 text-center">
                        <span class="font-weight-bold text-secondary text-xs"
                          >Interac</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="table-bd">
                <div
                  class="table-bd-row"
                  v-for="(hisAction, index) in historyActions"
                  :key="index"
                >
                  <div class="row">
                    <div class="col-4 p-0">
                      <div class="row">
                        <div class="col-8 pl-0 pr-0 pt-2 pb-2">
                          <a href="#" @click="levantarModal(hisAction)">
                            <img
                              :src="hisAction.post_imagen"
                              width="30px"
                              height="30px"
                              onerror="this.onerror=null;this.src='https://cdn4.iconfinder.com/data/icons/solid-part-6/128/image_icon-128.png';"
                            />
                          </a>
                          <span
                            class="ml-2 font-weight-bold text-secondary text-xs"
                            >{{ hisAction.name }}</span
                          >
                        </div>
                        <div
                          class="col-4 p-0 text-center d-flex justify-content-center align-items-center"
                        >
                          <span class="text-xs">
                            <i
                              :class="[
                                'fas',
                                hisAction.sentimentId == 1
                                  ? 'tb_neutral'
                                  : hisAction.sentimentId == 2
                                  ? 'tb_pos'
                                  : hisAction.sentimentId == 3
                                  ? 'tb_neg'
                                  : 'fa-ban',
                              ]"
                              style="font-size: 16px; color: #828a96"
                            ></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-4 p-0 d-flex align-items-center">
                      <div class="row w-100">
                        <div class="col-4 p-0 text-center">
                          <span class="text-color-text text-xs">{{
                            hisAction.organicImpressions
                          }}</span>
                        </div>
                        <div class="col-4 p-0 text-center">
                          <span class="text-color-text text-xs">{{
                            hisAction.organicReach
                          }}</span>
                        </div>
                        <div class="col-4 p-0 text-center">
                          <span class="text-color-text text-xs">{{
                            hisAction.organicInteractions
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-4 p-0 d-flex align-items-center">
                      <div class="row w-100">
                        <div class="col-4 p-0 text-center">
                          <span class="text-color-text text-xs">{{
                            hisAction.paidImpressions
                          }}</span>
                        </div>
                        <div class="col-4 p-0 text-center">
                          <span class="text-color-text text-xs">{{
                            hisAction.paidReach
                          }}</span>
                        </div>
                        <div class="col-4 p-0 text-center">
                          <span class="text-color-text text-xs">{{
                            hisAction.paidInteractions
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-row>

          <div v-if="!tableactionsvisibility" class="image-container-off">
            <img
              class="images-off opacity-50"
              src="../../../../assets/all-images/img-off-01.png"
              alt=""
            />
            <div class="image-container-off__message">
              <div>{{ titleImgOff }}</div>
              {{ msgImgOff }}
            </div>
          </div>
        </b-col>
        <b-col cols="6">
          <div v-if="!paidorganivisibility" class="image-container-off">
            <img
              class="images-off opacity-50"
              src="../../../../assets/all-images/impresions-of.png"
              alt=""
            />
            <div class="image-container-off__message">
              <div>{{ titleImgOff }}</div>
              {{ msgImgOffGraphic }}
            </div>
          </div>
          <impresiones v-else ref="impresionesGraf" :grafico="this.grafInter">
          </impresiones>
        </b-col>
      </b-row>
      <b-row class="row-grafs">
        <b-col cols="6">
          <div class="mb-3">
            <span
              class="font-weight-bold text-capitalize text-lsm text-secondary mr-1"
            >
              Impact
            </span>
            <span class="text-secondary">
              <i
                id="impact1"
                class="fa fa-info-circle text-sm text-skygray"
              ></i>
              <CpTooltipWhiteCustom
                target="impact1"
                title="Tooltip title"
                message="Number of interactions by reach and impressions"
              ></CpTooltipWhiteCustom>
            </span>
          </div>
          <highcharts
            v-if="impactvisibility"
            style="display: inherit !important"
            :options="optionFirst"
          >
          </highcharts>
          <div
            v-else
            class="image-container-off"          
          >
            <img
              src="../../../../assets/all-images/img-off-03.png"
              alt=""
              class="images-off"
            />
            <div class="image-container-off__message">
              <div>{{ titleImgOff }}</div>
              {{ msgImgOffGraphic }}
            </div>
          </div>
        </b-col>
        <b-col cols="6" class="inpactTabs">
          <b-tabs v-if="interreactiosvisibility" align="center">
            <b-tab active>
              <template #title>
                <span>Interaction Analysis</span>
              </template>
              <interactionsAnalytics
                ref="interactionsanalisis"
                :grafico="this.grafInter"
              >
              </interactionsAnalytics>
            </b-tab>

            <b-tab>
              <template #title>
                <span>Reactions </span>
                <i class="fab fa-facebook"></i>
              </template>
              <reactionsFacebook
                ref="reactionsFacebook"
                :grafico="this.grafInter"
              ></reactionsFacebook>
            </b-tab>

            <b-tab>
              <template #title>
                <span> Reactions </span><i class="fab fa-linkedin"></i>
              </template>
              <reactionLinkedin
                ref="reactionLinkedin"
                :grafico="this.grafInter"
              ></reactionLinkedin>
            </b-tab>
          </b-tabs>
          <div
            v-else
            class="image-container-off"
          >
            <img
              src="../../../../assets/all-images/analitycs-off.png"
              alt=""
              class="images-off opacity-50"
            />
            <div class="image-container-off__message">
              <div>{{ titleImgOff }}</div>
              {{ msgImgOff }}
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="row-grafs">
        <b-col>
          <div class="mb-3">
            <span
              class="font-weight-bold text-capitalize text-lsm text-secondary mr-1"
            >
              Overall Sentiment
            </span>
            <span>
              <i
                id="overrall-sentiment"
                class="fa fa-info-circle text-sm text-skygray"
              ></i>
              <CpTooltipWhiteCustom
                target="overrall-sentiment"
                title="Tooltip title"
                message="Sentiment generated daily in the publications associated with the campaign."
              >
              </CpTooltipWhiteCustom>
            </span>
          </div>
          <div v-if="!overaalsentimentvisibility" class="image-container-off">
            <img
              src="../../../../assets/all-images/chart_action_sent_bg.png"
              alt=""
              class="images-off"
            />
            <div class="image-container-off__message">
              <div>{{ titleImgOff }}</div>
              {{ msgImgOffGraphic }}
            </div>
          </div>
          <highcharts
            v-else
            class="grafico_all"
            style="display: inherit !important"
            :options="optionsSecond"
          ></highcharts>
        </b-col>
      </b-row>
    </b-container>
    <div v-if="overaalsentimentvisibility == false" class="clearImage"></div>
    <contentPost :obj="objpost" ref="modalcont"></contentPost>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import interactionsAnalytics from "@/components/all/detail-campaign/tabs/interactionsAnalytics.vue";
import reactionsFacebook from "@/components/all/detail-campaign/tabs/reactionsFacebook.vue";
import reactionLinkedin from "@/components/all/detail-campaign/tabs/reactionLinkedin.vue";
import impresiones from "@/components/all/detail-campaign/tabs/impresiones.vue";
import contentPost from "@/components/all/detail-campaign/ContentPostModal";
import * as CampaignServices from "@/services/campaign.service";
import Multiselect from "vue-multiselect";
import moment from "moment";
import * as GrafoCampaignService from "@/services/grafo-campaign.service";
import {
  loaderCustom,
  CpTooltipTableCustom,
  CpTooltipWhiteCustom,
  mapGetters,
  Helpers
} from "@isc/styleguide";
export default {
  components: {
    Multiselect,
    interactionsAnalytics,
    reactionsFacebook,
    reactionLinkedin,
    impresiones,
    loaderCustom,
    contentPost,
    CpTooltipTableCustom,
    CpTooltipWhiteCustom,
  },
  props: {
    idMarcasx: Number,
    fec_ini: String,
    fec_fin: String,
    timezoneId: String,
  },
  computed: {
    ...mapGetters({
      msgImgOffGraphic: "global/helper/getImageOffGraphic",
      msgImgOff: "global/helper/getImageOff",
      titleImgOff: "global/helper/getTitleImgOff",
    }),
  },
  data() {
    return {
      fixNameSocialNetwork: (name) => Helpers.fixNameSocialNetwork(name),
      rangeDate: [moment().subtract(15, "days").toDate(), moment().toDate()],
      arrSocialNetworkSelected: [],
      arrActionsSelected: [],
      nameDate: true,
      budgetType: false,
      confirmation: false,
      idRedSocial: null,
      idCampaign: null,
      redSocial: [],
      actions: [],
      valueActions: null,
      multiple: true,
      valueNetworks: [],
      listPostHitoricoAction: [],
      historyActions: [],
      historyActionsAll: [],
      options: [],
      urlImagePost: "",
      contentPostName: "",
      rutaPost: "",
      grafInter: [],
      interacanalysis: {},
      reactfb: {},
      reactln: {},
      optionsSecond: {
        chart: {
          type: "column",
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          marginTop: 10,
          height: 360,
          marginBottom: 80,
        },
        title: {
          text: null,
        },
        xAxis: {
          type: "datetime",
          lineColor: "#D6DBDF",
          tickLength: 0,
          categories: [],
        },
        credits: {
          text: "",
        },
        yAxis: {
          labels: {
            style: {
              color: "#B3B4B8",
            },
          },
          min: 0,
          title: {
            text: null,
            enabled: false,
            gridLineColor: "#D5DAE0",
          },
          stackLabels: {
            enabled: false,
          },
        },
        legend: {
          align: "right",
          x: 10,
          verticalAlign: "bottom",
          y: 10,
          floating: true,
          borderWidth: 0,
          shadow: false,
          itemStyle: {
            fontWeight: "normal",
            color: "#6F7478",
            fontSize: "10px",
          },
        },
        tooltip: {
          formatter: function () {
            let text =
              "<table class='table_tooltip' border='0' cellspacing='0' cellpadding='0'>";
            text +=
              "<tr><td colspan='2' class='tp-table'>" + this.x + "</td></tr>";
            for (let i = 0; i < this.points.length; i++) {
              text += "<tr><td style='padding:4px;padding-left:8px'>";
              text +=
                "<i class='fa fa-circle' style='margin-right:4px;vertical-align:middle;font-size:6px;color:" +
                this.points[i].series.color +
                " !important'></i> " +
                this.points[i].series.name;
              text +=
                "</td><td style='padding:4px;text-align:right;padding-right:8px;font-weight:bold;'>";
              text += this.points[i].y;
              text += "</td></tr>";
            }
            text += "</table>";
            return text;
          },
          shared: true,
          padding: 0,
          backgroundColor: "#ffffff",
          useHTML: true,
          shadow: false,
          borderWidth: 0,
          borderRadius: 0,
          crosshairs: true,
        },
        exporting: {
          enabled: false,
        },
        plotOptions: {
          series: {
            pointWidth: 5,
            allowPointSelect: true,
          },
          bar: {
            stacking: "normal",
            pointWidth: 7,
          },
          column: {
            stacking: "normal",
            dataLabels: {
              enabled: false,
            },
            pointWidth: 7,
          },
        },
        series: [],
      },
      optionFirst: {
        title: {
          text: "",
        },
        xAxis: {
          categories: [], //['Apples', 'Oranges', 'Pears', 'Bananas', 'Plums']
        },
        credits: {
          text: "",
        },
        yAxis: {
          labels: {
            style: {
              color: "#B3B4B8",
            },
          },
          min: 0,
          title: {
            text: null,
            enabled: false,
            gridLineColor: "#D5DAE0",
          },
          stackLabels: {
            enabled: false,
          },
        },
        legend: {
          itemStyle: {
            fontWeight: "normal",
            color: "#6F7478",
            fontSize: "10px",
          },
        },
        labels: {
          items: [
            {
              html: "", //'Total fruit consumption',
              style: {
                left: "50px",
                top: "18px",
                color: "black",
              },
            },
          ],
        },
        series: [],
      },
      visibilityLoader: false,
      tableactionsvisibility: true,
      impactvisibility: true,
      overaalsentimentvisibility: true,
      interreactiosvisibility: true,
      paidorganivisibility: true,
      objpost: {},
      columnsTooltipTable: [
        {
          header: "Paid Posts",
          body: {
            facebook: {
              value: true,
              message: "Reach and impressions.",
            },
            instagram: {
              value: false,
              message: "",
            },
            linkedin: {
              value: true,
              message: "Interactions and impressions.",
            },
            twitter: {
              value: true,
              message: "Interactions and impressions.",
            },
            youtube: {
              value: true,
              message: "No paid campaigns are carried out.",
            },
          },
        },
        {
          header: "Organic Posts",
          body: {
            facebook: {
              value: true,
              message: "Impressions, interactions and reach.",
            },
            instagram: {
              value: true,
              message: "Impressions, interactions and reach.",
            },
            linkedin: {
              value: true,
              message: "Impressions and interactions.",
            },
            twitter: {
              value: true,
              message: "Impressions and interactions.",
            },
            youtube: {
              value: true,
              message: "Impressions and interactions.",
            },
          },
        },
      ],
      oldSelectedChannels: [],
    };
  },
  async mounted() {
    try {
      this.visibilityLoader = true;
      this.idCampaign = this.$route.query.campaign;
      const respGetOverallSentimentGraph = await this.getOverallSentiment();
      const respGetImpactGraph = await this.getImpactGraph();
      await this.getListRedSocial();
      this.optionsSecond.series = respGetOverallSentimentGraph.series || [];
      let firstserie = 0;
      let seconderie = 0;
      let thirdserie = 0;
      firstserie = Object.values(
        respGetOverallSentimentGraph?.series[0]?.data
      ).reduce((a, b) => a + b);
      seconderie = Object.values(
        respGetOverallSentimentGraph?.series[1]?.data
      ).reduce((a, b) => a + b);
      thirdserie = Object.values(
        respGetOverallSentimentGraph?.series[2]?.data
      ).reduce((a, b) => a + b);
      if (firstserie > 0 || seconderie > 0 || thirdserie > 0) {
        this.overaalsentimentvisibility = true;
      } else {
        this.overaalsentimentvisibility = false;
      }
      this.optionsSecond.xAxis.categories =
        respGetOverallSentimentGraph?.categories || [];
      this.optionFirst.series = respGetImpactGraph?.series || [];
      if (
        respGetImpactGraph?.series[0]?.data.length > 0 ||
        respGetImpactGraph?.series[1]?.data.length > 0 ||
        respGetImpactGraph?.series[2]?.data.length > 0
      ) {
        this.impactvisibility = true;
      } else {
        this.impactvisibility = false;
      }
      
      let modifiedCategoriesNames = [];
      if(respGetImpactGraph && respGetImpactGraph.categories.length > 0){
        modifiedCategoriesNames = respGetImpactGraph.categories.map(category => {
          category = category.replace("Suscriptors", "Video");
          return category;
        })
      }
      this.optionFirst.xAxis.categories = modifiedCategoriesNames;
      await this.getInteractionGraf();
    } catch (error) {
      console.error("[Error] in cycle created function: ", error);
    } finally {
      this.visibilityLoader = false;
    }
  },
  methods: {
    async dispatchAction(evento) {
      //console.log("EVENTO ", evento);
      //console.log("SELECTED SOCIAL NETWORKS  ", this.arrSocialNetworkSelected);
      if (evento.length == 0) {
        this.historyActions = [];
        this.actions = [];
        this.arrActionsSelected = [];
      } else {
        //* Clear select action and filter.
        if (
          this.oldSelectedChannels.length === 0 ||
          this.oldSelectedChannels.length !==
            this.arrSocialNetworkSelected.length
        ) {
          this.arrActionsSelected = [];
          //* Filter
          let tempActionsFilter = this.filterbyRss(evento);
          this.historyActions = tempActionsFilter;
          this.actions = tempActionsFilter;
          this.oldSelectedChannels = [...this.arrSocialNetworkSelected];
        }
      }
      //* this is for showing up table metrics posts
      if (this.historyActions.length > 0) {
        this.tableactionsvisibility = true;
      } else {
        this.tableactionsvisibility = false;
      }
    },
    async selectActionMultiple(evento) {
      if (this.arrActionsSelected.length > 0) {
        this.historyActions = evento;
      } else {
        let tempActionsFilter = this.filterbyRss(this.arrSocialNetworkSelected);
        this.historyActions = tempActionsFilter;
      }
      if (this.historyActions.length > 0) {
        this.tableactionsvisibility = true;
      } else {
        this.tableactionsvisibility = false;
      }
    },
    async getListRedSocial() {
      const _listRedSocial =
        (await CampaignServices.listRedSocial(this.$route.query.campaign)) ||
        [];
      if (_listRedSocial === -1) {
        this.$bvToast.toast("There was an error to get social networks.", {
          title: "Error",
          autoHideDelay: 5000,
          appendToast: true,
          variant: "danger",
          solid: true,
        });
      }
      this.redSocial = [];
      if (_listRedSocial.length > 0) {
        for (const rss of _listRedSocial) {
          this.redSocial.push({
            id: rss.idRedesSociales,
            name: this.fixNameSocialNetwork(rss.nombreRedSocial),
            id_red_social: rss.idRedesSociales,
            referencia: rss.referenciaSocial,
          });
        }
        this.arrSocialNetworkSelected = this.redSocial;
      }
      this.getListActions();
    },
    async selectedRedSocial() {
      await this.getListRedSocial();
    },
    async getListActions() {
      if (this.arrSocialNetworkSelected.length > 0) {
        try {
          const DataSend = {};
          L;
          DataSend.id_campania = this.idCampaign;
          DataSend.arrSocialNetwork = this.arrSocialNetworkSelected;
          const _listPostHitoricoAction =
            await CampaignServices.listPostHitoricoAction(DataSend);

          if (_listPostHitoricoAction === -1) {
            this.$bvToast.toast("Actions could not be loaded.", {
              title: "Error",
              autoHideDelay: 5000,
              appendToast: true,
              variant: "danger",
              solid: true,
            });
          }
          if (_listPostHitoricoAction != undefined) {
            this.actions = _listPostHitoricoAction || [];
            this.listPostHitoricoAction = _listPostHitoricoAction || [];
            this.historyActions = _listPostHitoricoAction || [];
            this.historyActionsAll = _listPostHitoricoAction || [];
          }

          if (this.historyActions.length > 0) {
            this.tableactionsvisibility = true;
          } else {
            this.tableactionsvisibility = false;
          }
        } catch (error) {
          //console.log(error);
        }
      }
    },
    addPost(_listPostHitoricoAction) {
      if (_listPostHitoricoAction.length > 0) {
        for (var i = 0; i < _listPostHitoricoAction.length; i++) {
          let result = i % 2;
          this.addRowsTable(_listPostHitoricoAction[i], result);
        }
      }
    },
    levantarModal(objPost) {
      this.objpost = objPost;
      this.$refs.modalcont.show();
    },
    //* filterr all posts from only slected social networks
    filterbyRss(evento) {
      let tempActionsFilter = [];
      for (const item of evento) {
        let filterbyRss = this.historyActionsAll.filter(
          (action) => action.id_red_social == item.id_red_social
        );
        tempActionsFilter.push(...filterbyRss);
      }
      return tempActionsFilter;
    },
    selectSocialNetwork(event) {
      var arraySelect = [];
      for (let index = 0; index < this.valueNetworks.length; index++) {
        arraySelect.push(this.valueNetworks[index].id);
      }
    },
    sumInteracciones(intecciones) {
      let total = 0;
      Object.entries(intecciones).forEach(([key, value], index) => {
        total += value;
      });
      return total;
    },
    actionReferencia(historic) {
      var referencia = "";
      for (var red = 0; red < this.redSocial.length; red++) {
        if (this.redSocial[red].id == historic.id_red_social) {
          referencia = this.redSocial[red].referencia;
        }
      }
    },
    async getOverallSentiment() {
      try {
        const respGet = await GrafoCampaignService.getOveralSentiment(
          this.idCampaign,
          this.fec_ini,
          this.fec_fin,
          this.timezoneId
        );
        return respGet.data;
      } catch (error) {}
    },
    async getImpactGraph() {
      try {
        const respGet = await GrafoCampaignService.getImpactGraph(
          this.idCampaign,
          this.rangeDate[0],
          this.rangeDate[1]
        );
        return respGet.data;
      } catch (error) {}
    },
    async getInteractionGraf() {
      const id_campanias = this.$route.query.campaign;
      let result = await CampaignServices.getGraficoTotalizado(id_campanias);

      if (
        result?.paidmetrics?.impressionspaid > 0 ||
        result?.paidmetrics?.impressionsoorganic > 0
      ) {
        this.paidorganivisibility = true;
      } else {
        this.paidorganivisibility = false;
      }
      if (
        result?.analytics?.likes > 0 ||
        result?.analytics?.dislikes > 0 ||
        result?.analytics?.retweets > 0 ||
        result?.analytics?.shares > 0 ||
        result?.analytics?.comments > 0 ||
        result?.reactionsfb?.like > 0 ||
        result?.reactionsfb?.love > 0 ||
        result?.reactionsfb?.haha > 0 ||
        result?.reactionsfb?.wow > 0 ||
        result?.reactionsfb?.sad > 0 ||
        result?.reactionsfb?.angry > 0 ||
        result?.reactionsln?.like > 0 ||
        result?.reactionsln?.celebrate > 0 ||
        result?.reactionsln?.curious > 0 ||
        result?.reactionsln?.love > 0 ||
        result?.reactionsln?.insightful > 0 ||
        result?.reactionsln?.support > 0
      ) {
        this.interreactiosvisibility = true;
      } else {
        this.interreactiosvisibility = false;
      }
      this.$refs.interactionsanalisis.Generate(result.analytics);
      this.$refs.impresionesGraf.Generate(result.paidmetrics);
      this.$refs.reactionsFacebook.Generate(result.reactionsfb);
      this.$refs.reactionLinkedin.Generate(result.reactionsln);
    },
  },
};
</script>
<style>
.bv-example-row {
  margin: 0px !important;
  padding: 0px;
}

.even {
  background-color: #f7f8f9 !important;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.tooltip_st {
  display: inline-block;
  position: relative;
  top: 1px;
}

.tooltip_st span {
  visibility: hidden;
  width: 300px;
  background-color: #00a5ff;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 20px;
  position: absolute;
  z-index: 1;
  transition: all 0.3s ease-in-out;
  margin-left: -150px;
  font-size: 12px;
  font-weight: normal;
  font-family: "Oxygen";
  top: 25px;
  left: 1px;
}

.tooltip_st span:hover {
  visibility: visible !important;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #00a5ff;
  position: absolute;
  top: -5px;
  left: 50%;
}

.iscsub {
  font-family: "Oxygen";
  font-size: 15px;
  font-weight: 600;
  color: #2c3852;
  text-align: left;
  padding: 10px;
}

.graf_off_3 {
  display: none;
  background: url("https://www.isocialcube.com/home/img/img-off-03.png")
    no-repeat center center;
  background-size: contain;
  width: 100%;
  min-height: 360px;
  z-index: 10;
}

.tb_act_01 {
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 2px;
  border-color: grey;
  border-spacing: 0;
  border-collapse: collapse;
}

.btm_th {
  background-color: #f9f9f7 !important;
  border-color: #c7cfe2 !important;
  border-bottom: 1px solid #c7cfe2 !important;
}

.tooltip-inner {
  background-color: #00a5ff;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-weight: normal;
  font-family: "Oxygen";
}

.tooltip .arrow::before {
  border-top-color: #00a5ff;
}

.tabsActions {
  color: #fff;
  border-radius: 3px;
  box-shadow: 0px 2px 3.9px 0.1px rgb(0 0 0 / 15%);
  background-color: #00a5ff;
}

.multiselect__option--highlight {
  background: #eef8ff !important;
  color: #000000 !important;
}

.multiselect__option--highlight:after {
  background: #eef8ff !important;
  color: #000000 !important;
}

.container {
  max-width: 100% !important;
}

.multiselect.selectSize {
  float: left;
  width: 120px;
}
.selectSize .multiselect__content-wrapper {
  font-size: 12px;
}
.selectSize .multiselect__input {
  font-size: 12px;
}
.col {
  padding-left: 0px;
}

.selectLabel {
  width: 75px;
  float: left;
  padding: 0px !important;
  font-size: 16px;
  font-weight: normal;
  color: #5d6b88;
  margin-top: 5px;
}

.actionLabel {
  position: absolute;
  left: 250px;
}

.actionLabel .selectLabel {
  width: 80px;
}

.container-action {
  margin-top: 40px;
}

.multiselect__tag {
  background: #00a5ff !important;
}

.title-style {
  font-size: 0.9375em;
  text-align: center;
  color: #333b52;
  font-weight: bold;
}

.impress {
  position: relative;
  right: 50px;
}

.row-grafs {
  margin-top: 2rem;
}

.images-off {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.clearImage {
  width: 100%;
  height: 80px;
}

.multiselect__single {
  font-size: 12px;
  font-weight: normal;
  color: #adadad;
}

.multiselect__tags {
  min-height: 35px !important;
  height: 35px;
  box-shadow: inset 0px 1px 3px 1px rgb(72 72 72 / 18%);
}

.multiselect__placeholder {
  padding-top: 0px;
  padding-bottom: 2px;
  font-size: 12px;
}

.inpactTabs .nav-tabs {
  border-bottom: none;
}

.inpactTabs .nav-tabs .nav-link {
  border: none;
  height: 35px;
  display: flex;
  font-size: 0.75em;
  font-weight: bold;
  color: #333b52;
  align-items: center;
  border-radius: 3px;
}

.inpactTabs .nav-tabs .nav-link i {
  color: #a2acc4;
  margin-left: 3px;
}

.inpactTabs .nav-tabs .nav-link:hover,
.inpactTabs .nav-tabs .nav-link:hover i {
  color: #00a5ff;
}

.inpactTabs .nav-tabs .nav-link.active {
  background-color: #00a5ff;
}

.inpactTabs .nav-tabs .nav-link.active,
.inpactTabs .nav-tabs .nav-link.active i {
  color: #fff;
}

.interactionGeneral__box {
  width: 70%;
  margin: 60px auto 0 auto;
}

.interactionGeneral__box--line {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
}

.interactionGeneral__box--line:last-child {
  margin-bottom: 0;
}

.interactionGeneral__box--line__range--baseLine {
  width: 185px;
  height: 8px;
  background-color: #f3f4f7;
  position: relative;
}

.interactionGeneral__box--line__range--baseLine__width {
  position: absolute;
  height: 100%;
  background-color: #1877f2;
  top: 0;
  left: 0;
}

.interactionGeneral__box--line__ico {
  width: 90px;
}

.interactionGeneral__box--line__ico,
.interactionGeneral__box--line__count {
  color: #5d6b88;
  font-size: 0.75em;
}

.interactionGeneral__box--line__count {
  flex-grow: 1;
  padding-left: 30px;
  font-weight: bold;
}

.table-bd {
  height: 230px;
  overflow-y: auto;
}

.table-head-bg {
  background-color: #f9f9f7;
}

.second-th {
  border-bottom: 1px solid #c7cfe2;
}

.top-th,
.second-th {
  height: 30px;
}

.table-bd-row {
  height: 45px;
}

.table-bd-row:nth-child(odd) {
  background: #ffffff;
}

.table-bd-row:nth-child(even) {
  background: #f9f9f7;
}

.iconPaid {
  color: #5d6b88;
  font-size: 10px;
  padding-left: 0.2rem;
}

@media (max-width: 1024px) {
  .interactionGeneral__box {
    width: 85%;
    margin: 40px auto 0 auto;
  }

  .multiselect.selectSize {
    min-width: 125px !important;
  }

  .table-bd {
    height: 210px;
  }
}
</style>
