<template>
  <div>
    <loader-custom v-if="visibilityLoader"></loader-custom>
    <div
      class="first-block w-100 d-flex justify-content-between align-items-center position-relative"
      v-custom-click-outside="hideDropdown"
    >
      <nav v-if="boolHome"></nav>
      <nav v-if="!boolHome" class="d-flex">
        <a @click="getToListening()" :class="getClassSidebar('listen')"
          >LISTEN</a
        >
        <a href="/overview/accounts" :class="getClassSidebar('monitor')"
          >MONITOR</a
        >
        <a
          @click="getToDesign()"
          :class="getClassSidebar('design')"
          class="other"
          >DESIGN</a
        >
        <a
          @click="getToAdvise()"
          :class="getClassSidebar('advise')"
          class="other"
          >ADVISE</a
        >
        <a
          v-if="isBackOfficeVisible"
          @click="getToBackoffice()"
          :class="getClassSidebar('backoffice')"
          class="other"
        >
          BACKOFFICE
        </a>
      </nav>
      <div class="d-flex justify-content-end">
        <div class="icon_name">
          <div>
            <span
              >Welcome , <b class="mr-2">{{ nombreUsuario }}</b
              ><b> ID: {{ idNameGroup }}</b></span
            >
          </div>
          <img
            @click="showModal = true"
            class="profile_img"
            :src="url"
            alt="foto de perfil"
          />
        </div>
        <div class="button-config" @click="toggleDropdown">
          <i class="fa fa-cog fa-2x"></i>
        </div>
      </div>
      <div class="dropdown-core" v-show="openedDropdown">
        <a :href="link.url" v-for="(link, index) in links" :key="index">
          <i :class="link.icon"></i> <span>{{ link.label }}</span>
        </a>
        <a @click="Logout" style="cursor: pointer">
          <i class="fa fa-power-off"></i> <span>Logout</span>
        </a>
      </div>

      <b-modal
        v-model="showModal"
        title="Upload Logo"
        @hidden="resetModal"
        @close="breakUpload"
      >
        <div
          class="d-flex flex-column justify-content-center align-items-center"
          style="padding: 1rem 0"
        >
          <img
            @click="showModal = true"
            class="profile_img_modal"
            :src="url"
            alt="foto de perfil"
          />
          <div>
            <p class="text-md mt-4 mb-4" style="font-size: 15px">Select file</p>
            <input
              type="file"
              class="text-md"
              style="font-size: 15px"
              @change="uploadFile"
            />
            <p class="text-xs mb-2" style="color: red">{{ textoValidacion }}</p>
          </div>
        </div>

        <template #modal-footer>
          <div class="d-flex justify-content-end">
            <b-button
              variant="light"
              class="text-decoration-none text-semidark"
              style="margin-right: 7px; font-size: 15px"
              @click="
                showModal = false;
                breakUpload();
              "
              >Close</b-button
            >
            <b-button variant="primary" @click="updateImageProfile"
              >Upload File</b-button
            >
          </div>
        </template>
      </b-modal>
    </div>
    <!-- ALERT CODE MODAL -->
    <IscModalAlerts
      v-model="showAlertCode"
      :icon="iconCode"
      :iconColor="iconColorCode"
      :title="titleCode"
      :paragraph="paragraphCode"
      :buttonMessage="buttonMessageCode"
      @sendResult="resultModalAlertsCode"
      :noCloseBackdrop="true"
      :refresh="true"
    >
    </IscModalAlerts>
    <!-- MODAL ALERT GLOBAL -->
    <IscModalAlerts
      v-model="showAlert"
      :icon="icon"
      :iconColor="iconColor"
      :title="title"
      :paragraph="paragraph"
      :buttonMessage="buttonMessage"
      @sendResult="resultModalAlerts"
      :noCloseBackdrop="true"
      :showBtn="showBtn"
      :showMainIcon="showMainIcon"
      :showBtnClose="showBtnClose"
    >
    </IscModalAlerts>
  </div>
</template>

<script>
import {
  mapActions,
  CodeToken,
  AlertSetting,
  IscModalAlerts,
  loaderCustom,
} from "@isc/styleguide";

export default {
  name: "Header",
  components: {
    IscModalAlerts,
    loaderCustom,
  },
  mixins: [CodeToken, AlertSetting],
  data() {
    return {
      visibilityLoader: false,
      showModal: false,
      openedDropdown: false,
      links: [
        {
          icon: "fa fa-user",
          url: "/settings/profile",
          label: "Profile",
        },
        {
          icon: "fa fa-cog",
          url: "/settings/settings",
          label: "Settings",
        },
        {
          icon: "fa fa-th-large",
          url: "/settings/quickstar",
          label: "Quick Start",
        },
      ],
      objUsuario: {},
      url: require("@/assets/icons/usuario-desconocido.jpg"),
      urlAnterior: "",
      textoValidacion: "",
      boolHome: false,
      nombreUsuario: "",
      rutaActual: "",
      isUploadedPic: false,
      isClient: false,
      currentUsername: null,
      dscNames: "",
      imgProfile: null,
      idNameGroup: "",
      httpEndpoint: process.env.VUE_APP_API_BACKOFFICE_URL,
      isBackOfficeVisible: false,
      token: sessionStorage.getItem("token"),
      refreshToken: sessionStorage.getItem("refreshToken"),
    };
  },
  async mounted() {
    this.visibilityLoader = true;
    try {
      if(!this.token || !this.refreshToken){
        await this.checkSentTokenAndSetHeaderInfo();
      } else {
        await this.getIsClientOrUsuario();
        this.setInfoHeader();
      }
      this.rutaActual = window.location.pathname;
      if (window.location.pathname === "/settings/home") {
        this.boolHome = true;
      }
      if (
        window.location.pathname === "/settings/home" ||
        window.location.pathname === "/settings/quickstar"
      ) {
        this.boolHome = true;
      }
    } catch (error) {
      this.errorModal(error.response.data.message);
    }finally {
      this.visibilityLoader = false;
    }

  },
  methods: {
    ...mapActions({
      isClientOrUsuario: "global/helper/IS_CLIENT_OR_USUARIO",
      isAdmin: "global/helper/IS_ADMIN",
      updateImgProfileClient: "topNavbar/header/UPDATE_USER_IMG_PROFILE_CLIENT",
      updateImgProfileUsuario:"topNavbar/header/UPDATE_USER_IMG_PROFILE_USUARIO",
      logoutSession: "login/session/SESSION_LOGOUT",
    }),
    async getIsClientOrUsuario() {
      try {
        const respClientOrUser = await this.isClientOrUsuario();
        const { response } = this.validationCodeToken(respClientOrUser.data);
        this.isClient = response.isClientOrUsuario;
        this.currentUsername = response.username;
        this.dscNames = response.dscNames;
        this.imgProfile = response.imgProfile;
        this.idNameGroup = response.idNameGroup;

        const respIsAdmin = await this.isAdmin(response.idNameGroup);
        if(respIsAdmin && respIsAdmin.data && respIsAdmin.data.isAdmin){
          this.isBackOfficeVisible = respIsAdmin.data.isAdmin
        }

      } catch (error) {        
        console.log("[Error] in function getIsClientOrUsuario: ", error);
      }
    },
    setInfoHeader() {
      // this putting into a function
      let boolCorrectFormatImg = false;

      try {
        this.nombreUsuario = this.dscNames;
        const urlSplit = (this.imgProfile || "").split("/");
        if (urlSplit[0] === "data:image") {
          boolCorrectFormatImg = true;
        }
      } catch (error) {
        console.log(error);
      }

      if (this.imgProfile && boolCorrectFormatImg) {
        this.url = this.imgProfile;
        this.urlAnterior = this.imgProfile;
      } else {
        this.url = require("@/assets/icons/usuario-desconocido.jpg");
        this.urlAnterior = require("@/assets/icons/usuario-desconocido.jpg");
      }
    },
    toggleDropdown() {
      this.openedDropdown = !this.openedDropdown;
    },
    hideDropdown() {
      this.openedDropdown ? (this.openedDropdown = false) : null;
    },
    resetModal() {
      this.textoValidacion = "";
    },
    //* ==== PRE UPLOAD IMAGE PROFILE (ONLY CHARGE THE IMG) ==== */
    async uploadFile(evento) {
      this.textoValidacion = "";
      const file = evento.target.files[0];
      const fileSizeKb = file.size * 0.001;
      // In general, for online purposes, your logo size should be under 200 KB.
      //The larger your image file is, the heavier it is, which makes it more challenging for websites.
      if (fileSizeKb > 200) {
        this.textoValidacion = "The maximum size of the image is 200 KB";
        return;
      }
      //* Validation format
      if (!this.validationFormatImg(file)) return;
      this.url = await this.handleFile(file);
    },
    handleFile(blob) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    },
    validationFormatImg(file) {
      const nameSplit = file.name.split(".");
      const format = nameSplit[nameSplit.length - 1].toLowerCase();
      if (!(format === "jpg" || format === "jpeg" || format === "png")) {
        this.textoValidacion = "Image format not valid.";
        return false;
      }
      return true;
    },
    //* ==== FINISH =====
    breakUpload() {
      if (!this.isUploadedPic) {
        this.url = this.urlAnterior;
      } else {
        this.isUploadedPic = false;
        this.url = this.urlAnterior;
      }
    },
    async updateImageProfile() {
      try {
        this.loadingModal("Uploading Image!");
        if (this.textoValidacion !== "") {
          this.textoValidacion =
            " It can not be uploaded before fixing the format.";
        }
        const urlSplit = this.url.split("/");
        // let dataCliente = JSON.parse(localStorage.getItem("userObj"));
        if (urlSplit[0] === "data:image") {
          let updatedResponse;
          if (this.isClient) {
            const resp = await this.updateImgProfileClient({
              dscImgProfile: this.url,
            });
            updatedResponse = this.validationCodeToken(resp.data);
          } else {
            const resp = await this.updateImgProfileUsuario({
              dscImgProfile: this.url,
            });
            updatedResponse = this.validationCodeToken(resp.data);
          }

          if (updatedResponse.status === "success") {
            this.urlAnterior = this.url;
            this.isUploadedPic = true;
            await this.getIsClientOrUsuario();
            this.setInfoHeader();
            this.showButtonsModal();
            this.successModal(updatedResponse.message);
          } else {
            this.errorModal("An error occurred, please try again.");
          }
        }
      } catch (error) {
        console.log("[Error] in function updateImageProfile: ", error);
        this.showButtonsModal();
        this.errorModal(error.response.data.message);
      }
    },
    showButtonsModal() {
      this.showAlert = false;
      this.showBtn = true;
      this.showBtnClose = true;
      this.showMainIcon = true;
    },
    //* ==== END TO UPLOAD IMAGE TO DATABASE ====
    //* ===== DIRECTION TO URLS =====
    async Logout() {
      try {
        this.visibilityLoader = true;
        await this.logoutSession();
      } catch (error) {
        console.log("[Error] in function: Logout", error);
      } finally {
        this.cleanStorageAndRedirectToLogin();
      }
    },
    getClassSidebar(ruta) {
      let arrSplit = this.rutaActual.split("/");
      if (ruta == "listen") {
      } else if (ruta == "monitor" && arrSplit.includes("accounts")) {
        return "main";
      }
      return "other";
    },
    cleanStorageAndRedirectToLogin() {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/login";
    },
    async getToListening() {
      try {
        const _TOKEN = sessionStorage.getItem("token");
        const _REFRESH_TOKEN = sessionStorage.getItem("refreshToken");
        window.location.href =
          process.env.VUE_APP_LISTENING + _TOKEN + "&refresh=" + _REFRESH_TOKEN;
      } catch (error) {
        console.error("error en getToListening: ", error);
      }
    },
    async getToDesign() {
      try {
        const _TOKEN = sessionStorage.getItem("token");
        const _REFRESH_TOKEN = sessionStorage.getItem("refreshToken");
        window.location.href =
          process.env.VUE_APP_DESIGN + _TOKEN + "&refresh=" + _REFRESH_TOKEN;
      } catch (error) {
        console.error("error en getToDesign: ", error);
      }
    },
    async getToAdvise() {
      try {
        const _TOKEN = sessionStorage.getItem("token");
        const _REFRESH_TOKEN = sessionStorage.getItem("refreshToken");
        window.location.href =
          process.env.VUE_APP_ADVISE + _TOKEN + "&refresh=" + _REFRESH_TOKEN;
      } catch (error) {
        console.error("error en getToAdvise: ", error);
      }
    },

    async getToBackoffice() {
      try {
        const _TOKEN = sessionStorage.getItem("token");
        const _REFRESH_TOKEN = sessionStorage.getItem("refreshToken");
        window.location.href =
          process.env.VUE_APP_BACKOFFICE + _TOKEN + "&refresh=" + _REFRESH_TOKEN;
      } catch (error) {
        console.error("error en getToBackoffice: ", error);
      }
    },
    async checkSentTokenAndSetHeaderInfo() {
      window.addEventListener("tokens-changed", async (event) => {
      const token = event.detail.token;
      const refresh = event.detail.refreshToken;
       if (token && refresh) {
        await this.getIsClientOrUsuario();
        this.setInfoHeader();
       } else {
        throw new Error("Error validating tokens!");
       } 

    });
    }
  },
};
</script>
<style scoped>
.first-block {
  height: 60px;
  background-color: var(--primary);
}
.first-block nav {
  padding-left: 24px;
}
.first-block nav .other {
  font-size: 12px;
  line-height: 37px;
  padding: 0 20px;
  margin-right: 15px;
  border: 1px solid rgba(0, 0, 0, 0);
  text-align: center;
  color: white;
  font-weight: 600;
  cursor: pointer;
  height: 37px;
}
.first-block nav .other:focus {
  outline: none;
}
.first-block nav .other:hover {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 3px;
  background-color: transparent;
  color: #fff;
}
.first-block nav .main {
  border-radius: 3px;
  font-size: 12px;
  line-height: 37px;
  padding: 12px 20px;
  margin-right: 16px;
  color: var(--primary);
  text-align: center;
  font-weight: 600;
  background-color: white;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.first-block nav .main:focus {
  outline: none;
}
/*-----BUTTON CONFIG----*/
.button {
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
}
.button-cancel {
  background-color: var(--skygray);
  margin-right: 1rem;
}
.button-ok {
  background-color: var(--primary);
  color: white;
  transition: all ease-in 0.2s;
}
.button-ok:hover {
  opacity: 70%;
}
.button-config {
  width: 70px;
  height: 60px;
  background-color: var(--secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.button-config i {
  color: var(--skygray);
}
.dropdown-core {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: 50;
  padding: 15px 0;
  top: 60px;
  min-width: 250px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  background: #f1f1f1 url("../assets/icons/logofullv.png") no-repeat scroll
    177px 15px;
}
.dropdown-core a {
  display: block;
  font-size: 12px;
  text-align: left;
  padding: 10px 20px 20px 20px;
  color: #566b8a;
  transition: all ease 0.2s;
}
.dropdown-core a:hover {
  color: var(--primary);
}
.dropdown-core a i {
  font-size: 16px;
  margin-right: 1rem;
}
/*-----PROFILE-----*/
.icon_name {
  color: white;
  display: flex;
  align-items: center;
  margin-right: 1rem;
  height: 60px;
  font-size: 12px;
}
.icon_name > div {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}

.profile_img {
  border-radius: 9999px;
  cursor: pointer;
  width: 40px;
  height: 40px;
}
.profile_img_modal {
  border-radius: 9999px;
  width: 150px;
  height: 150px;
}
</style>
