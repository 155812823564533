<template>
  <div>
    <b-modal
      v-model="model"
      size="lg"
      title=""
      hide-footer
      :no-enforce-focus="true"
      :no-close-on-backdrop="true"
    >
      <div class="container-alerts">
        <div v-if="showSectionIcon" class="flex-center">
          <i
            v-if="showMainIcon"
            class="flex-center icon"
            :class="icon"
            :style="{ color: iconColor }"
          ></i>
          <div class="spinner" v-else>
            <b-spinner variant="primary" label="Spinning"> </b-spinner>
          </div>
        </div>
        <p class="container-alerts__title">
          <strong>{{ title }}</strong>
        </p>
        <p class="container-alerts__paragraph" data-cy="message-alert">
          {{ paragraph }}
        </p>
        <div v-if="showBtn" class="container-alerts__buttons" aria-hidden="false">
          <b-button
            :variant="variantColorBtn1"
            class="px-3 border-1"
            @click="sendResultBtn1"
            >{{ btnMsg1 }}</b-button
          >
          <b-button
            :variant="variantColorBtn2"
            class="px-3"
            @click="sendResultBtn2"
            >{{ btnMsg2 }}</b-button
          >
        </div>
        <div class="spinner container-alerts__spinner" v-else>
          <b-spinner variant="primary" label="Spinning"> </b-spinner>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "IscDoubleBtnModal",
  components: {},
  props: {
    showBtn: {
      type: Boolean,
      default: true,
    },
    showBtnClose: {
      type: Boolean,
      default: false,
    },
    showMainIcon: {
      type: Boolean,
      default: true,
    },
    showSectionIcon: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    iconColor: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    paragraph: {
      type: String,
      default: "",
    },
    btnMsg1: {
      type: String,
      default: "",
    },
    btnMsg2: {
      type: String,
      default: "",
    },
    variantColorBtn1: {
      type: String,
      default: "light",
    },
    variantColorBtn2: {
      type: String,
      default: "primary",
    },
    value: {
      type: Boolean,
      default: false,
    },
    refresh: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    sendResultBtn1() {
      this.$emit("send-result-btn1");
    },
    sendResultBtn2() {
      this.$emit("send-result-btn2");
    },
    close(value) {
      this.$emit("input", value);
      if (this.refresh) this.sendResult();
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .modal-dialog {
  width: 566px;
}
::v-deep .modal-content {
  height: 274px;
}
::v-deep .modal-header {
  display: none !important;
}

::v-deep .close {
  font-size: 30px !important;
}

::v-deep .modal {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.container-alerts {
  height: 100%;
  align-content: center;
  &__title {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    padding-top: 1rem;
    padding-bottom: 1.5rem;
  }
  &__paragraph {
    font-family: "Oxygen";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #2c3852;
  }
  &__buttons {  
    display: flex;
    justify-content: center;
    gap: 20px;
    padding-top: 30px;
    padding-bottom: 1.1rem;
  }
  &__spinner {
    text-align: center;
  } 
}
.spinner {
  padding-top: 44px;
}
.container-alerts .icon {
  font-size: 28px;
}
</style>
