import axios from "axios";
import { getLocalStorage } from "../utils/utils";
const URL = process.env.VUE_APP_API_MARKET_TRENDS;

async function axiosInstance(network) {
  return await axios.create({
    headers: getLocalStorage(),
    baseURL: `${URL}/${network}`
  });
}

export async function validatePostInsights(body, network) {
  const data = await postInsights(body, network);
  if (data === null) return null;
  return data;
}

export async function validatePostGeodrivers(network) {
  const data = await postGeodrivers(network);
  if (data === null) return null;
  return data;
}

async function postInsights(body, network) {
  try {
    const aInstance = await axiosInstance(network);
    const { data } = await aInstance.post("/insights", body);
    return data;
  } catch (error) {
    console.log("error: ", error.message);
    return null;
  }
}

async function postGeodrivers(network) {
  try {
    const aInstance = await axiosInstance(network);
    const { data } = await aInstance.post("/geodrivers");
    return data;
  } catch (error) {
    console.log("error: ", error.message);
    return null;
  }
}
