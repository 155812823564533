import axios from "axios";
//* PROD URL CAMPAIGN
const base_url = `${process.env.VUE_APP_BASE_URL}/grafos-campaign/`;
//* LOCAL URL
//const base_url = "http://localhost:3000/api/v1/grafos-campaign/";

const { validationCodeToken } = require("../util/validateCodeToken");
import { getCredentialsHeader } from "../util/util";

export const allSemantic = async (id_campania) => {
  try {
    let configAxios = {};
    configAxios.method = "get";
    configAxios.url = `${base_url}allsemantic`;
    configAxios.headers = getCredentialsHeader();
    configAxios.params = {
      id_campania: id_campania,
    };
    const data = await axios(configAxios);
    let result = validationCodeToken(data);

    return result.data;
  } catch (error) {
    return -1;
  }
};

export const allsemanticsXRedSocial = async (id_campania, network) => {
  try {
    let configAxios = {};
    configAxios.method = "get";
    configAxios.url = `${base_url}allsemanticsXRedSocial`;
    configAxios.headers = getCredentialsHeader();
    configAxios.params = {
      id_campania: id_campania,
      network: network,
    };
    const data = await axios(configAxios);
    let result = validationCodeToken(data);

    return result.data;
  } catch (error) {
    return -1;
  }
};
export const allSemanticXPost = async (id_post) => {
  try {
    let configAxios = {};
    configAxios.method = "get";
    configAxios.url = `${base_url}allSemanticXPost`;
    configAxios.headers = getCredentialsHeader();
    configAxios.params = {
      id_post: id_post,
    };
    const data = await axios(configAxios);
    let result = validationCodeToken(data);

    return result.data;
  } catch (error) {
    return -1;
  }
};

export const getActions = async (id_campania, redsocial) => {
  try {
    let configAxios = {};
    configAxios.method = "get";
    configAxios.url = `${base_url}getpost`;
    configAxios.headers = getCredentialsHeader();
    configAxios.params = {
      id_campania: id_campania,
      redsocial: redsocial,
    };
    const data = await axios(configAxios);
    let result = validationCodeToken(data);

    return result.data;
  } catch (error) {
    return -1;
  }
};

export const getOveralSentiment = async (id_campania, fechaini, fechafin, timezoneId) => {
  try {
    let configAxios = {};
    configAxios.method = "get";
    configAxios.url = `${base_url}getOverallSentimentGraph`;
    configAxios.headers = getCredentialsHeader();
    configAxios.params = {
      fechaini: fechaini,
      fechafin: fechafin,
      campaign_id: id_campania,
      timezone_id: timezoneId
    };
    const data = await axios(configAxios);
    let result = validationCodeToken(data);
    return result.data;
  } catch (error) {
    return -1;
  }
};
export const getImpactGraph = async (idCampaign, fechaini, fechafin) => {
  try {
    let configAxios = {};
    configAxios.method = "get";
    configAxios.url = `${base_url}getImpactGraph`;
    configAxios.headers = getCredentialsHeader();
    configAxios.params = {
      fechainicio: fechaini,
      fechafin: fechafin,
      id_campania: idCampaign,
    };
    const data = await axios(configAxios);
    let result = validationCodeToken(data);
    return result.data;
  } catch (error) {
    return -1;
  }
};

export const getSentimentGraph = async (fechaini, fechafin, id, type, rss, timezoneId) => {
  try {
    let configAxios = {};
    configAxios.method = "get";
    configAxios.url = `${base_url}getsentimentGraph`;
    configAxios.headers = getCredentialsHeader();
    configAxios.params = {
      id: id,
      type: type,
      rss: rss,
      fechaini: fechaini,
      fechafin: fechafin,
      timezone_id: timezoneId
    };
    const data = await axios(configAxios);
    let result = validationCodeToken(data);
    return result.data;
  } catch (error) {
    return -1;
  }
};
