export const getObjUsuarioSession = () => {
  try {
    const dataObjUsuario = JSON.parse(sessionStorage.getItem("userObj"));
    return dataObjUsuario;
  } catch (error) {
    return { code: 1, error: new Error(error).message };
  }
};

export const getCredentialsHeader = () => {
  const _TOKEN = sessionStorage.getItem('token')
  //const _REFRESH_TOKEN = sessionStorage.getItem('refreshToken')
  const _IP_PUBLIC = sessionStorage.getItem('ip-public')
  const _IP_LOCAL = sessionStorage.getItem('ip-local')
  return  {
    Authorization: _TOKEN+'|'+_IP_PUBLIC+'|'+_IP_LOCAL,
  }; 
}