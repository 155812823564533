export function prefix(location, ...prefixes) {
  return prefixes.some(
    prefix => location.href.indexOf(`${location.origin}/${prefix}`) !== -1
  );
}
export function authorization(location){
  return prefix(location, "auth");
}
export function sidebarNav() {
  // The nav is always active
  return true;
}

export function topNavbar() {
  // The nav is always active
  return !prefix(location, 'login');
}

export function benchmark(location) {
  return prefix(location, 'benchmark');
}

export function marketTrends(location) {
  return prefix(location, 'market-trends');
}

export function overview(location) {
  return prefix(location, 'overview');
}

export function campaign(location) {
  return prefix(location, 'campaign');
}

export function settings(location) {
  return prefix(location, 'settings');
}
export function login(location) {
  return prefix(location, 'login');
}


