var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    {
      staticClass: "authorization",
      staticStyle: { "overflow-y": "hidden !important" },
    },
    [_c("div", { staticClass: "main-container" }, [_c("loaderCustom")], 1)]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }