export default {
  loader: false,
  msgImgOffGraphic: "There is not enough data to create a graphic.",
  msgImgOff: "There is not enough data to show.",
  titleImgOff: "Image off",
  isNoAuth: false, 
  msgNoAuth: {
    title: "No Authorization",
    message: "No Authorization, it will redirect to login.",
  },

};
