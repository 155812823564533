import { registerApplication, start } from "single-spa";
import * as isActive from "./activity-functions";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

registerApplication(
  "@isc/authorization",
  () => System.import("@isc/authorization"),
  isActive.authorization,
  { domElement: document.getElementById("authorization") }
);

registerApplication(
  "@isc/sidebar-nav",
  () => System.import("@isc/sidebar-nav"),
  isActive.sidebarNav,
  {
    domElement: document.getElementById("sidebar-nav"),
  }
);

registerApplication(
  "@isc/navbar",
  () => System.import("@isc/navbar"),
  isActive.topNavbar,
  {
    domElement: document.getElementById("top-navbar"),
  }
);

// registerApplication('@isc/market-trends', () => System.import('@isc/market-trends'), isActive.marketTrends, {
//     domElement: document.getElementById('market-trends'),
// });
registerApplication({
  name: "@isc/market-trends",
  app: () => System.import("@isc/market-trends"),
  activeWhen: ["/market/industry", "/market/search"],
});

registerApplication(
  "@isc/benchmark",
  () => System.import("@isc/benchmark"),
  isActive.benchmark,
  {
    domElement: document.getElementById("benchmark"),
  }
);

registerApplication(
  "@isc/overview",
  () => System.import("@isc/overview"),
  isActive.overview,
  {
    domElement: document.getElementById("overview"),
  }
);

registerApplication(
  "@isc/campaign",
  () => System.import("@isc/campaign"),
  isActive.campaign,
  {
    domElement: document.getElementById("campaign"),
  }
);

registerApplication(
  "@isc/settings",
  () => System.import("@isc/settings"),
  isActive.settings,
  {
    domElement: document.getElementById("settings"),
  }
);

registerApplication(
  "@isc/login",
  () => System.import("@isc/login"),
  isActive.login,
  {
    domElement: document.getElementById("login"),
  }
);

start();
