var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-100 bg-mainbg" },
    [
      _vm.visibilityLoader || _vm.loader ? _c("loader-custom") : _vm._e(),
      _c("bread-crumb"),
      _c(
        "div",
        { staticClass: "mt-3" },
        [
          _c(
            "b-container",
            { staticClass: "detail-content-box", attrs: { fluid: "" } },
            [
              _c(
                "div",
                { staticClass: "w-100 d-flex justify-content-end mb-2" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "shadow px-5 py-2",
                      staticStyle: { "font-size": "13px" },
                      attrs: { variant: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.modalAddAccount = true
                        },
                      },
                    },
                    [_vm._v(" Add Account ")]
                  ),
                ],
                1
              ),
              _vm.accounts.length > 0
                ? _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex flex-wrap justify-content-between",
                      },
                      _vm._l(_vm.accounts, function (account, index) {
                        return _c("account-box", {
                          key: index,
                          attrs: { accountIndex: index, accountData: account },
                          on: {
                            reload: _vm.getAccounts,
                            error: _vm.emitError,
                            ok: _vm.emitOk,
                            okUpdate: _vm.emitOkUpdate,
                          },
                        })
                      }),
                      1
                    ),
                    _c("div", { staticClass: "row pd-lr-10" }, [
                      _c("div", { staticClass: "col-md-12" }, [
                        _c("div", { attrs: { id: "paginado" } }, [
                          _c(
                            "ul",
                            { attrs: { id: "pagination" } },
                            _vm._l(_vm.totalPages, function (index) {
                              return _c(
                                "li",
                                {
                                  key: index,
                                  class: [
                                    "li_paginado",
                                    index == _vm.currentpage
                                      ? "select_paginado"
                                      : "",
                                  ],
                                  attrs: { id: "1" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getAccounts(index)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(index) + " ")]
                              )
                            }),
                            0
                          ),
                          _c("div", { staticClass: "clear" }),
                        ]),
                      ]),
                    ]),
                  ])
                : _c("cp-warning-info", {
                    attrs: {
                      "info-text":
                        "Add an account and link the social media channels to start using MONITOR.",
                      "padding-container": "40px 20px",
                    },
                  }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            "no-close-on-backdrop": "",
            size: "lg",
            "header-class": "border-0 pr-5 btn-close-header",
            "hide-footer": "",
            "no-enforce-focus": true,
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header-close",
              fn: function () {
                return [
                  _c("CloseButton", {
                    staticClass: "p-0",
                    attrs: { fill: _vm.customFill },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.modalAddAccount,
            callback: function ($$v) {
              _vm.modalAddAccount = $$v
            },
            expression: "modalAddAccount",
          },
        },
        [
          _c("add-account-modal", {
            attrs: { idClient: _vm.idClient },
            on: { onClose: _vm.getResult },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }