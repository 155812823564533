import axios from "axios";
// overview back
const base_url = require('../util/config').rutaService
const respUserSession = require('../util/util').getObjUsuarioSession() || {}
const { validationCodeToken } = require('../util/validateCodeToken')
import { getCredentialsHeader } from "../util/util";
export const insertRestrictAccount = async (dataConsume) => {
  try {
    dataConsume.idCliente = respUserSession.id_cliente;
    let jsonAxios = {
      method: 'post',
      url: `${base_url}/restrict/save-period-consume`,
      headers: getCredentialsHeader(),
      data: dataConsume
    }
    const { data } = await axios(jsonAxios);

    return validationCodeToken(data)
  } catch (error) {
    return error.response;
  }
};
export const updateRestrictAccount = async (dataConsume, idModule) => {
  try {
    
    let jsonAxios = {
      method: 'put',
      url: `${base_url}/restrict/update-period-consume`,
      headers: getCredentialsHeader(),
      data: {
        data: dataConsume,
        clientId: respUserSession.id_cliente,
        idModule
      }
    }
    const { data } = await axios(jsonAxios);

    return validationCodeToken(data)
  } catch (error) {
    return error.response;
  }
};