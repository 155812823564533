import gql from "graphql-tag";

export const GET_IS_ADMIN = gql(`
    query isAdmin($cognitoGroup: String!) {
          isAdmin(cognitoGroup: $cognitoGroup)
      }
`);

export const GET_VALIDATE_TOKEN = gql(`query validateJWT($token: String,$refresh: String, $ip_public: String, $ip_local: String) {
    validateJWT(token: $token, refresh: $refresh, ip_public: $ip_public, ip_local: $ip_local) {
      code
      message
      messageError
      decode
      dateExpiredToken
      newToken
    }
  }
  `);
