var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("router-view"),
      _c("cp-double-btn-modal", {
        attrs: {
          title: _vm.titleModalBtn,
          paragraph: _vm.paragraphModalBtn,
          btnMsg1: "No",
          btnMsg2: "Yes",
          showBtn: _vm.isShowBtn,
        },
        on: {
          "send-result-btn1": _vm.redirectToLogin,
          "send-result-btn2": _vm.setTokenAndSession,
        },
        model: {
          value: _vm.isShowModalBtn,
          callback: function ($$v) {
            _vm.isShowModalBtn = $$v
          },
          expression: "isShowModalBtn",
        },
      }),
      _c("cp-isc-modal-no-auth"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }