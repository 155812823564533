var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { attrs: { "no-gutters": "" } },
    [
      _c(
        "b-col",
        { staticClass: "mt-2 pl-3 pr-5", attrs: { cols: "8" } },
        [
          _c("div", { staticClass: "mb-4 d-flex align-items-center" }, [
            _c("span", { staticClass: "title-style" }, [
              _vm._v("New Likes per Day "),
            ]),
            _c("i", {
              directives: [
                {
                  name: "b-popover",
                  rawName: "v-b-popover.hover",
                  value: {
                    variant: "primary",
                    content: "Fans Trend",
                  },
                  expression:
                    "{\n          variant: 'primary',\n          content: 'Fans Trend',\n        }",
                  modifiers: { hover: true },
                },
              ],
              staticClass: "fa fa-info-circle icoPop",
            }),
          ]),
          !_vm.boolViewData
            ? _c("div", { staticClass: "image-container-off" }, [
                _c("img", {
                  staticStyle: { opacity: "0.5", width: "100%" },
                  attrs: {
                    src: require("@/assets/images/image-off-line-graph.png"),
                    alt: "",
                  },
                }),
                _c("div", { staticClass: "image-container-off__message" }, [
                  _c("div", [_vm._v(_vm._s(_vm.titleImgOff))]),
                  _vm._v(" " + _vm._s(_vm.msgImgOff) + " "),
                ]),
              ])
            : _c("highcharts", { attrs: { options: _vm.getOptions() } }),
        ],
        1
      ),
      _c(
        "b-col",
        {
          staticClass:
            "pl-5 pr-0 pt-2 mt-5 d-flex justify-content-between align-items-start panels flex-wrap box-column-trend",
          attrs: { cols: "4" },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "bg-facebook h-24 rounded d-flex flex-column justify-content-center align-items-center",
            },
            [
              _c(
                "p",
                {
                  staticClass: "white-color-text font-weight-bold text-center",
                },
                [_vm._v("New Likes")]
              ),
              _c(
                "p",
                { staticClass: "bx_Content_color text-white text-center" },
                [
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(
                      " " + _vm._s(_vm.numFormatter(_vm.dataList.likes)) + " "
                    ),
                  ]),
                  _c("i", { staticClass: "fa fa-sort-up text-white" }),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "bg-warning h-24 rounded d-flex flex-column justify-content-center align-items-center",
            },
            [
              _c(
                "p",
                {
                  staticClass: "white-color-text font-weight-bold text-center",
                },
                [_vm._v("Unlikes")]
              ),
              _c(
                "p",
                { staticClass: "bx_Content_color text-white text-center" },
                [
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(
                      " " + _vm._s(_vm.numFormatter(_vm.dataList.unlikes)) + " "
                    ),
                  ]),
                  _c("i", { staticClass: "fa fa-sort-up text-white" }),
                ]
              ),
            ]
          ),
          _c(
            "div",
            [
              _c("p", { staticClass: "text-trend text-center mb-3" }, [
                _vm._v("Growth"),
              ]),
              _c(
                "vc-donut",
                {
                  attrs: {
                    sections: [
                      { value: _vm.dataList.growth, color: "#0a2aee" },
                    ],
                    size: 115,
                    thickness: 10,
                    "auto-adjust-text-size": true,
                  },
                },
                [
                  _c("span", { staticClass: "text-donut" }, [
                    _vm._v(_vm._s(_vm.dataList.growth) + "%"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("p", { staticClass: "text-trend text-center mb-3" }, [
                _vm._v("Fans Lost per day"),
              ]),
              _c(
                "vc-donut",
                {
                  attrs: {
                    sections: [{ value: _vm.dataList.lost, color: "#0a2aee" }],
                    size: 115,
                    thickness: 10,
                    "auto-adjust-text-size": true,
                  },
                },
                [
                  _c("span", { staticClass: "text-donut" }, [
                    _vm._v(_vm._s(_vm.dataList.lost) + "%"),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }