
import Store from "../store/index";
import { MSG_ERRORS_NO_AUTH,MSG_NO_AUTH_INVALID_TOKEN, MSG_NO_AUTH_SESSION, MSG_ERRORS_INVALID_TOKEN } from "../lib/constants";
export const setErrorAndShowModal = (error) => {
  const msgError = error.response?.data?.message || null;
  //* SESSIONS
  if(MSG_ERRORS_NO_AUTH.includes(msgError)){
    const msg = {
      title: "",
      message: MSG_NO_AUTH_SESSION
    }
    Store.dispatch("global/helper/MSG_NO_AUTH", msg);
    Store.dispatch("global/helper/TRIGGER_MODAL_NO_AUTH");  
   //* TOKEN 
  } else if(MSG_ERRORS_INVALID_TOKEN.includes(msgError)){
    const msg = {
      title: "",
      message: MSG_NO_AUTH_INVALID_TOKEN
    }
    Store.dispatch("global/helper/MSG_NO_AUTH", msg);
    Store.dispatch("global/helper/TRIGGER_MODAL_NO_AUTH");
  } 
};