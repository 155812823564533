<template>
  <div class="container">
    <router-view />
    <cp-double-btn-modal
      v-model="isShowModalBtn"
      :title="titleModalBtn"
      :paragraph="paragraphModalBtn"
      btnMsg1="No"
      btnMsg2="Yes"
      :showBtn="isShowBtn"
      @send-result-btn1="redirectToLogin"
      @send-result-btn2="setTokenAndSession"
    />
  </div>
</template>
<script>
import {
  CpDoubleBtnModal,
  DoubleBtnMixin,
  ExpTokenMixin
} from "@isc/styleguide";

export default {
  name: "App",
  components: {
    CpDoubleBtnModal
  },
  mixins: [DoubleBtnMixin, ExpTokenMixin],
  methods: {}
};
</script>
<style scoped>
.container {
  /* margin-left: var(--navbar-width); */
  margin: 0 !important;
  width: 100% !important;
  max-width: 100% !important;
  padding: 0px !important;
}
</style>
