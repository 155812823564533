import moment from "moment";
export const getObjUsuarioSession = () => {
  try {
    const dataObjUsuario = JSON.parse(sessionStorage.getItem("userObj"));
    return dataObjUsuario;
  } catch (error) {
    return { code: 1, error: new Error(error).message };
  }
};

export const orderSocialNetworks = (arr, attr) => {
  return arr.sort((a, b) =>
    a[attr] < b[attr] ? -1 : a[attr] > b[attr] ? 1 : 0
  );
};

export const orderStringAlphabetically = (arr) => {
  return arr.sort((a, b) => (a < b ? -1 : a > b ? 1 : 0));
};

export const getCurrentTimestamp = () => {
    const now = moment(); // Create a moment object for the current date and time
    const currentTimestamp = now.format("YYYY-MM-DD HH:mm:ss");
    return currentTimestamp;

};
export const getCredentialsHeader = () => {
  const _TOKEN = sessionStorage.getItem('token')
  //const _REFRESH_TOKEN = sessionStorage.getItem('refreshToken')
  const _IP_PUBLIC = sessionStorage.getItem('ip-public')
  const _IP_LOCAL = sessionStorage.getItem('ip-local')
  return  {
    Authorization: _TOKEN+'|'+_IP_PUBLIC+'|'+_IP_LOCAL,
  }; 
}
