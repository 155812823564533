<template>
  <div class="w-8/12 pl-3 pr-5">
    <div class="mb-4">
      <div class="d-flex align-items-center">
        <span
          class="font-weight-bold text-capitalize text-lsm text-secondary mr-2"
        >
          Accumulated Audience
        </span>
        <i
          class="fa fa-info-circle text-skygray"
          v-b-popover.hover="{
            variant: 'primary',
            content: 'Total amount of daily audience',
          }"
        ></i>
      </div>
    </div>
    <div
     v-if="series.length === 0"
      class="image-container-off"
    >
      <img
        class="opacity-50"
        src="@/assets/images/off-accumulated-audience.png"
        alt=""
      />
      <div class="image-container-off__message">
        <div>{{ titleImgOff }}</div>
        {{ msgImgOff }}
      </div>
    </div>
    
    <graph-spline
      v-else
      :titlexAxis="titlexAxis"
      :arrTitlesyAxis="arrTitlesyAxis"
      :series="series"
      :categories="categories"
    ></graph-spline>
  </div>
</template>

<script>
import { mapGetters } from "@isc/styleguide";
import { rutaNotFound } from "../../../../util/config";
import graphSpline from "../../../graph-custom/graphSpline.vue";

export default {
  components: {
    graphSpline,
  },
  props: {
    series: {
      type: Array,
      default: [],
      required: true,
    },
    categories: {
      type: Array,
      default: [],
      required: true,
    },
  },
  data() {
    return {
      rutaNotFoundAny: rutaNotFound,
      titlexAxis: "Days",
      arrTitlesyAxis: ["Audience"],
    };
  },
  computed: {
    ...mapGetters({
      msgImgOff: "global/helper/getImageOffGraphic",
      titleImgOff: "global/helper/getTitleImgOff",
    }),
  },
  methods: {},
};
</script>

<style>
</style>
